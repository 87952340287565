export const TRANSLATIONS_EN = {
  signout: "Sign out",
  userprofile: "User profile",
  tea: "Tea",
  hwork: "How does it work?",
  about: "About",
  ourtea: "Our teas",
  subs: "Subscriptions",
  edit: "edit",
  back: "Back",
  save: "Save",
  paid: "Paid",
  expired: "Expired",
  failed: "Failed",
  canceled: "Paused",
  passworddonotmatch: "Passwords do not match.",
  passwordincorrect: "Password must be at least 8 characters long.",
  jwtexpired: "This link is no longer valid, request a new one.",
  myAddress: "My address",
  mySubscription: "My subscription",
  useralreadyexist: "This email address is already in use.",
  myAccount: "My account",

  price1: "€8,95 p/m",
  price2: "€16,95 p/m",
  price3: "€20,95 p/m",
  price4: "€23,95 p/m",
  price5: "€25,95 p/m",

  biological: "Organic",
  biological2: "Pure good organic tea in every cup.",
  quality1: "Quality",
  quality2: "A true tea experience.",
  delivery: "mailbox",
  delivery2: "For great tea at home always.",
  pause: "Pause",
  pause2:
      "Complete autonomy over your subscription to make it fit into your lifestyle or pause it when it suits you.",
  how1: "Choose the subscription that suits you and the tastiest tea flavours",
  how2: "Create an account and enter your shipping information",
  how3: "Monthly delicious tea Tealivered at your home",
  sub1a: "Relax",
  sub1b: "Pick up to 2 flavors for a bimonthly supply of 1 cup a day.",
  sub2a: "Yes, yes!",
  sub2b: "Pick up to 2 flavors for a monthly supply of 2 cups a day.",
  sub3a: "Cozy",
  sub3b: "Pick up to 3 flavors for a monthly supply of 3 cups a day.",
  sub4a: "Tea = life",
  sub4b: "Pick up to 4 flavors for a monthly supply of 4 cups a day.",
  sub5a: "Family fun",
  sub5b: "Pick up to 4 flavors for a monthly supply of 5 cups a day.",
  sub6: "Start my subscription",
  footer1: "About Us",
  footer2: "Qualitea",
  footer3: "Subscriptions",
  footer4: "Privacy statement",
  footer5: "General terms of condition",
  footer6: "FAQ's",
  footer7: "Contact",
  footer8: "Log in",
  footer9: "Info",
  footer10:
      "© 2022 Tealivered | Tealivered.nl | De Brink 112, 9723 AM Groningen | KVK: 83552774 | BTW: NL862914590B01",

  accpage1: "Subscription",
  accpage2: "Address details",
  accpage3: "Order history",
  accpage4: "Admin",
  accpage5: "Log out",

  adminview1: "Name",
  adminview2: "Subscriptiontype",
  adminview3: "Flavors",
  adminview4: "Customer since",
  adminview5: "Last payment",
  adminview6: "Address",

  history1: "Subscription",
  history2: "Flavors",
  history3: "Date",
  history4: "Paid",
  history5: "Invoice",

  mysubnonactive: "It looks like you don't have a subscription with us yet.",
  mysub1: "Ordernumber: ",
  mysub2: "Tea bags: ",
  mysub3: "Status: ",
  mysub4: "Subscriptiontype: ",
  mysub5: "Flavors: ",
  mysub6: "Next shipment: ",
  mysub7: "Customize Flavours",
  mysub8: "Cancel subscription",
  mysub11: "Start your subscription",
  mysub12: "Pause subscription",
  mysub13: "Are you sure you want to pause your subscription?",
  mysub14: "Yes, pause my subscription. See you again soon!",
  mysub15: "Do you want to resume your subscription?",
  mysub16: "Yes, activate my account",
  mysub17: "Resume subscription",
  mySub18: "Customize Flavours",

  myaddress1: "Name",
  myaddress2: "Address",
  myaddress3: "City",
  myaddress4: "Postal Code",

  register1: "Create a user page",
  register2: "Register",
  register3: "Already registered?",
  register4: "Log in",

  register5: "Name",
  register6: "Email address",
  register7: "Password",
  register8: "Password confirmation",

  login1: "First time here?",
  login2: "Go to register to select your tea.",
  login3: "Log in",
  login4: "Log in",
  login5: "Forgot password?",
  login6: "Email address and/or password is not valid.",

  forgotpassword1: "Forgot password?",
  forgotpassword2:
      "Can't remember the password? We will send an email within a few minutes to create a new password.",
  forgotpassword3:
      "Thank you, provided your e-mail address is known to us, you will receive an e-mail containing a personal link. You can enter a new password via the link.",
  forgotpassword4: "Email address",
  forgotpassword5: "Back to login",
  forgotpassword6: "Send",

  resetpassword1: "Reset password",
  resetpassword2: "Enter your new password here.",
  resetpassword3: "Your password has been reset.",
  resetpassword4: "Password",
  resetpassword5: "Back to login",
  resetpassword6: "Send",
  resetpassword7: "Confirm password",

  completedorder: "Order",
  completedorder2:
      "Thank you for choosing Tealivered. We'll get right to your order. Your order overview will be sent to your inbox and is also available on your user page.",

  order1: "Chose a subscription",
  order2: "Select how much tea you want to enjoy.",
  order3: "Select which tea you want to enjoy.",
  order4:
      "Would you prefer loose leafed tea or would you prefer tea bags? or would you prefer loose leafed tea?",
  order5: "Continue shipping",
  orderActive: "Your subscription is already active, first cancel your current subscription before you can start a new package.",

  shipping: "Shipping address",
  shipping2: "First name and surname",
  shipping3: "Address",
  shipping4: "City",
  shipping5: "Postal code",
  shipping6: "Place order",

  notfound: "This page is unavailable",

  zesttea: "Lemon green tea",
  zestteadesc: "a fine green tea with a lemon zest twist.",
  fruiteafred: "Forest fruit black tea",
  fruiteafreddesc: "Black tea and Forest fruits with a light sweet touch.",
  floraexplosion: "Floral Rooibos",
  floraexplosiondesc: "Easy going floral and rooibos tea.",
  sweattea: "Mix of ginger, chili pepper and mint.",
  sweatteadesc: "Herbal tea with flavors that kick.",
  capequeen: "Rooibos",
  capequeendesc: "Great caffeine free rooibos tea.",
  greengod: "Green tea",
  greengoddesc:
      "A round Japanese sencha green tea flavor that just feels right.",
  og: "Black tea",
  ogdesc: "Back to a flawless basic with Indian Assam black tea.",
  smoothearl: "Earl grey",
  smoothearldesc: "The well loved cup of tea.",

  faq1: "Frequently asked questions",
  faq2: "Could you tell what kind of teas you sell?",
  faq3: "There are 8 different teas and herbal blends we sell to provide good quality team without becoming overwhelmed by our assortiment.",
  faq4: "As an extra we also have 'De Thee'. This is a limited selection tea to try out a new flavor which you can add via your user page.",
  faq5: "Where are your teas from?",
  faq6: "We by from our wholesaler De Theefabriek which imports all organic teas from all over the world.",
  faq7: "Our O.G. Black tea is a nice strong tea from the Asssam region of India. Green God, our sencha green tea, is imported from Japan. The rooibos tea is from South Africa de rooibosthee, Our herbal blend 'Sweatea' is a homemade blend of indian and Egyptian spices, the Smooth Earl, earl grey tea, has origin tea plus.",
  faq8: "Subscription",
  faq9: "Can I only buy through subscription?",
  faq10:
      "No, you can also try 'Tease', our try out box. You'll get 60 grams of a tea of your choosing with one cup a day this will last you 30 days. If you liked the tea you can chose a subscription of that suits you to have a lasting supply of great tea.",
  faq11: "How to switch tea flavors: ",
  faq12:
      " Go to your user page and select the flavor of your liking. The reference date on your user pages tells you when the next order will be in. To make sure you are on time for your next shipping date you should switch flavors before the 15th of the next month. If you decide not to change tea blends you will recieven the same blends as before.",
  faq13: "Wat is een referentie datum?",
  faq14:
      "Reference date is the reference to the date of your original order on which the next shippings are based on. Therefore, this is also important for when your changing up your subscription. See 'how to switch tea flavors for more info.",
  faq15: "How to quit my subscription?",
  faq16:
      "On your user page you'l find a button to paus or stop your subscription. Whenever you decide to stop your subscription, please also sent us an e-mail via hoi@tealivered.nl that states you wish to stop. This is to make sure we will see it in time and delete your info from our database and your up coming orders will be canceled.",
  faq17: "I'm not that much of a tea drinker. Which subscription will suit me?",
  faq18:
      "Our 'Relax' subscription will most likely suit you best. You'll get a relaxing supply of tea to provide you with a cup a day for 60 days. This means iets a bimonthly subscription. Do you drink tea every single day we would advise a ' Yes, yes!' Subscription: this will provide you with 2 cups a day for a month. This way you'll have enough for yourself and for hosting a friend or family member who come over.",
  faq19:
      "I entertain a lot for friends and family. Does Tealivered supply me with enough tea to host guests?",
  faq20:
      "For frequent tea drinkers and hosts we would advice our 'Tea = life' subscription. Suits for frequent tea drinkers and hosting for others. Also will this provide you with up to 4 flavors of tea so there's something for everyone you entertain. Are there more frequent tea drinkers in your household? We would advice you to go for our 'Family Fun' subscription. This provides 5 cups of tea a day and you can choose up to 4 flavors.",
  faq21: "Can I change between subscriptions?",
  faq22:
      "Yes you can. Up to 14 days before you're next shipment you can change your subscription and get it tealivered in the up coming shipment. Go to your user page to select a different subscription.",
  faq23: "How does the monthly subscription and shipment work?",
  faq24:
      "From your second tea package, you will receive a tea package sent to your home every month. When your monthly tea package comes depends on your very first order. Did you place your first order before the 15th of the month? Then you will always receive your second, third, fourth (monthly) tea package at the beginning of the month. We aim to have the shipment at your home the first working day of the month. Did you place your first order on or after the 15th of the month? Then you will always receive your second, third, fourth (monthly) tea package around the middle of the month. We aim to have your shipment at your home around every 15th/first working day in the middle of the month.",
  faq25:
      "Through your reference date on your very first order you can make up next shipping dates.",
  faq26:
      "Only our Relax subscription is bimonthly. You will receive your tea subscription according to your reference date at the beginning of middle of every other month.",
  faq27: "How long will my subscription last?",
  faq28:
      "You can end your subscription through your account page and sending us an e-mail at hoi@tealivered.nl",
  faq29: "Shipment",
  faq30: "When will my tea be tealivered?",
  faq31: "Your very first tea box will be with you within 3 working days.",
  faq32: "How does the Tealivered monthly subscription work?",
  faq33:
      "After your very first tea box you will receive your tea box every month (unless if you have a Relax subscription). Depending on your very first order you will receive the next tea box at het beginning of every month (first box ordered before the 15th) or in the middle of every month (first box order after the 15th).",
  faq34:
      "Through your reference date on your very first order you can make up next shipping dates.",
  faq35: "Will it fit through my post box?",
  faq36:
      "We ship with PostNL. Minimal measurements are 26,5 cm long and 3,2 cm hight. The biggest box we use is an A4 box (35 x 25 x 2,5 cm). We use this for the 'cozy', 'tea - life' and ' family fun' subscriptions, the highest box we have is A5 and is 3,0 cm high (used for Relax or Yes, yes! subscriptions).",

  tos: "General terms and conditions",

  tos1: "Article 1 - Definitions",

  tos2:
      "In these terms and conditions: \n\n" +
      "Reflection period: the period within which the consumer can make use of his right of withdrawal; \n\n" +
      "Consumer/customer/orderer: the natural person who is not acting for purposes related to his trade, business, craft or profession; \n\n" +
      "Day: calendar day; \n\n" +
      "Digital content: data produced and delivered in digital form; \n\n" +
      "Duration agreement: an agreement that extends to the regular delivery of goods, services and/or digital content during a certain period; \n\n" +
      "Durable data carrier: any tool - including e-mail - that enables the consumer or entrepreneur to store information that is personally addressed to him in a way that future consultation or use during a period that is tailored to the purpose for which the information is intended, and which allows unaltered reproduction of the stored information; \n\n" +
      "Right of withdrawal: the consumer's option to cancel the distance contract within the cooling-off period; \n\n" +
      "Entrepreneur: the natural or legal person who is a participant in Tealivered and who offers products, (access to) digital content and/or services to consumers at a distance; \n\n" +
      "Distance contract: an agreement concluded between the entrepreneur and the consumer within the framework of an organized system for distance selling of products, digital content and/or services, whereby exclusive or joint use is made up to and including the conclusion of the agreement. is made of one or more remote communication techniques; \n\n" +
      "Model Withdrawal Form: the European Model Withdrawal Form included in Annex I of these terms and conditions; \n\n" +
      "Technique for distance communication: means that can be used for concluding an agreement, without the consumer and entrepreneur having to meet simultaneously in the same room \n\n" +
      "Reference date: date on which you placed your first order. This is used to determine subsequent deliveries.",

  tos3: "Article 2 - Identity of the entrepreneur",
  tos4:
      "Entrepreneurs: Bas Jetten, Cornelis Drewes bij de Leij and Zenash Naomi Hannah Pouwels. \n" +
      "Tealivered. \n" +
      "Business adress: De Brink 112, 9723 AM Groningen, the Netherlands \n" +
      "Emailadress: hoi@tealivered.nl  \n" +
      "Chamber of Commerce number: 83552774 \n" +
      "Tax number: NL862914590B01 \n",

  tos5: "Artikel 3 - Applicability ",
  tos6:
      "1. These general terms and conditions apply to every offer from the entrepreneur and to every distance agreement concluded between the entrepreneur and consumer. \n" +
      "2. Before the distance contract is concluded, the text of these general terms and conditions is made available to the consumer. If this is not reasonably possible, the entrepreneur will indicate, before the distance contract is concluded, how the general terms and conditions can be inspected by the entrepreneur and that they will be sent free of charge as soon as possible at the request of the consumer. \n" +
      "3. If the distance contract is concluded electronically, notwithstanding the previous paragraph and before the distance contract is concluded, the text of these general terms and conditions can be made available to the consumer electronically in such a way that it is the consumer can be stored in a simple way on a durable data carrier.If this is not reasonably possible, before the distance contract is concluded, it will be indicated where the general terms and conditions can be read electronically and that they are available at the request of the consumer. consumer will be sent free of charge electronically or otherwise. \n" +
      "4. In the event that specific product or service conditions also apply in addition to these general terms and conditions, the second and third paragraphs apply mutatis mutandis and the consumer can always invoke the applicable provision in the event of conflicting conditions. most favorable is \n" +
      "These conditions apply to all quotations, orders and agreements between the customer and Tealivered V.O.F. ''Tealivered'' \n" +
      "These conditions are accepted once upon first order and apply to further agreements in the future. \n" +
      "Agreement is concluded by correctly filling in the order and personal data when placing an order by the purchaser. \n",

  tos7: "Artikel 4 - The offer and payments",
  tos8:
      "1. If an offer has a limited period of validity or is made subject to conditions, this will be explicitly stated in the offer. \n" +
      "2. The offer contains a complete and accurate description of the products, digital content and/or services offered. The description is sufficiently detailed to enable a proper assessment of the offer by the consumer. If the entrepreneur uses images, are these a truthful representation of the products, services and/or digital content offered. Obvious mistakes or errors in the offer are not binding on the entrepreneur. \n" +
      "3. Each offer contains such information that it is clear to the consumer what rights and obligations are attached to the acceptance of the offer. \n" +
      "Prices \n" +
      "3.1 Tealivered reserves the right to change the prices of the products offered by it in subscription service form during the term of the service, whereby Tealivered will inform at least 14 days in advance and the customer has the option to cancel the service within 10 days. without the Purchaser being liable to pay any compensation. \n" +
      "3.3 Promotions and offers are valid while stocks last. \n" +
      "3.4 The personal Tealivered discount code is intended to recruit new paying customers from your own direct circle (through word of mouth, social media). The Tealivered discount codes may not be used for commercial purposes and/or purposes other than those for which they were issued. In addition under no circumstances is it allowed to use the brand name Tealivered and all possible combinations of misspellings within url combinations and thereby attract traffic that is used to distribute the discount code. \n" +
      "3.5 Any (attempted) fraud or other unauthorized action will be registered and will result in the use of discount codes being denied. Tealivered reserves the right to set off the discount obtained and the accumulated credit with retroactive effect. \n" +
      "3.6. The prices stated in the offer of products or services include VAT. \n" +
      "3.7. Price increases from 3 months after the conclusion of the agreement are only allowed if the entrepreneur has stipulated this and: \n" +
      "a. they are the result of legal regulations or provisions; or \n" +
      "b. the consumer has the authority to cancel the agreement with effect from the day on which the price increase takes effect.\n" +
      "Pay \n" +
      "4.1 For payment, the Orderer can only use the payment options offered by Tealivered. \n" +
      "4.2 By making the first payment via iDeal on the Tealivered, the Orderer gives Tealivered permission to also collect future payments from the relevant account number. \n" +
      "4.3 Payment is made no later than 14 days after a purchase agreement has been concluded. Periodic payment takes place prior to each delivery. \n" +
      "4.4 In case of late payment, for example due to reversal of the debited amounts or due to insufficient balance on the account concerned, an amount of EUR 2.50 can be charged. \n" +
      "4.5 If payment after the first reminder does not take place within the term set therein, Tealivered is entitled to unilaterally terminate the subscription with immediate effect, without prejudice to the right to compensation and the right to collect overdue payments and to recover the associated collection costs from the Orderer. \n" +
      "4.6 Invoices and payment reminders are only offered electronically by Tealivered. \n" +
      "4.7 The amount to be collected by Tealivered is equal to the amount of the order placed by the Orderer. \n" +
      "4.8 If the amount to be collected changes due to a change during the term of the subscription, an increase or a decrease, Tealivered retains the right to direct debit until the subscription is terminated. \n" +
      "​4.9​. The consumer has the obligation to immediately report inaccuracies in payment details provided or stated to the entrepreneur. \n" +
      "5. Contract \n" +
      "\n" +
      "The orderer can change or cancel his subscription with Tealivered at any time, taking into account the cancellation period of 14 days before the next delivery. If the change falls within those 14 days, the change will be processed at the next delivery.",
  tos9: "Artikel 5  - Delivery and Shipment",
  tos10:
      "Delivery and shipment \n" +
      "5.1. Tealivered delivers via postal parcel service throughout the Netherlands. \n" +
      "5. 1.1 Tealivered aims to deliver on the 1st of the month for anyone with a reference date between the 1st and 14th of the month. If the 1st of the month falls on a Monday or Sunday, delivery will take place on the following Tuesday. Tealivered aims to deliver on the 15th of the month for anyone with a reference date between the 15th and 31st of the month If the 15th of the month falls on a Monday or Sunday, delivery will be on the following Tuesday.\n" +
      "5. 1.2 Tealivered delivers to the address you specified in your account on our website as the delivery address. If you want to change the delivery address and it crosses a waiting delivery, it will be processed at the latest before the next delivery. \n" +
      "5. 1.3 If the letterbox transfer of the products is not possible, the agreement can be fulfilled by offering the products to neighbors or to the nearest collection point \n" +
      " 5. 1.4 If the products have not yet been paid for at the time of the personal transfer, Tealivered retains title until the purchase price has been paid in full. \n" +
      "5. 1.5 Tealivered only guarantees that the products it supplies are suitable for the purpose for which they are intended on the basis of the information provided by Tealivered and guarantees that the products comply with any legal obligations and other government regulations that apply to that purpose of use. \n" +
      " 5.2 Tealivered offers a sample package. One sample package can be ordered per residential address. This sample package will be sent once. \n" +
      " 5.3 Tealivered a GiftTea. With which you buy tea credit for a few months for someone else. \n" +
      "5.4 With due observance of what is stated in Article 4 of these general terms and conditions, the entrepreneur will execute accepted orders expeditiously but at the latest within 30 days, unless a different delivery period has been agreed. If the delivery is delayed, or if an order is not or can only be partially executed, the consumer will be notified of this no later than 30 days after he has placed the order.In that case, the consumer has the right to dissolve the agreement without costs and entitled to a refund of the purchase price.\n" +
      "5.5 Data Protection \n" +
      "To see which data Tealivered keeps and stores, you will find more information in the privacy statement.",
  tos11: "Artikel 6 - Agreement",
  tos12:
      "The agreement is concluded, subject to the provisions of Articles 4 and 5, at the moment of acceptance by the consumer of the offer and the fulfillment of the associated conditions. \n" +
      "6.1. The entrepreneur will electronically send the following information to the consumer no later than when the product, service or digital content is delivered: a. the address of the establishment; b. the e-mail address where the consumer can go with complaints; the conditions under which and the manner in which the consumer can make use of the right of withdrawal; d the price including all taxes of the product, service or digital content; if applicable, the costs of delivery; and the method of payment , delivery or performance of the distance contract; e) the requirements for terminating the agreement if the agreement has a duration of more than one year or is of indefinite duration; f) if the consumer has a right of withdrawal, the model withdrawal form. ",
  tos13: "Article 7 - Obligations of the consumer during the reflection period",
  tos14:
      "7.1. During the reflection period, the consumer will handle the product and the packaging with care. He will only unpack or use the product to the extent necessary to determine the nature, characteristics and functioning of the product. The basic principle here is that the consumer has tasted the product, but the vast majority of the product is present unused in the original packaging. \n",
  tos15:
      "Article 8 - Exercise of the right of withdrawal by the consumer and costs thereof",
  tos16:
      "8.1. If the consumer makes use of his right of withdrawal, he will report this within the reflection period by means of the model withdrawal form which can be found on the website. \n" +
      "8.2. As soon as possible, but within 14 days from the day following the notification referred to in paragraph 1, the consumer returns the product or hands it over to (an authorized representative of) the entrepreneur. This is not necessary if the entrepreneur has offered to collect the product himself. The consumer has in any case observed the return period if he returns the product before the reflection period has expired. \n" +
      "8.3. The consumer returns the product with all accessories supplied, if reasonably possible in its original condition and packaging, and in accordance with the reasonable and clear instructions provided by the entrepreneur. \n" +
      "8. 4. The risk and the burden of proof for the correct and timely exercise of the right of withdrawal rests with the consumer.\n" +
      "8.5. The consumer bears the direct costs of returning the product. If the entrepreneur has not reported that the consumer must bear these costs or if the entrepreneur indicates that he will bear the costs himself, the consumer does not have to bear the costs for return. .\n" +
      "8. 6. If the consumer exercises his right of withdrawal, all additional agreements will be terminated by operation of law.",
  tos17:
      "​Article 9 - Obligations of the entrepreneur in the event of withdrawal",
  tos18:
      "9.1. The entrepreneur makes the notification of withdrawal by the consumer electronically possible, he will immediately send a confirmation of receipt after receiving this notification. \n" +
      "9.2. The entrepreneur reimburses all payments from the consumer, including any delivery costs charged by the entrepreneur for the returned product, without delay but within 14 days following the day on which the consumer notifies him of the withdrawal. Unless the entrepreneur offers the product itself to collect the product, he may wait with repayment until he has received the product or until the consumer proves that he has returned the product, whichever is earlier. \n" +
      "9.3. The entrepreneur uses the same payment method for reimbursement that the consumer has used, unless the consumer agrees to another method. The refund is free of charge for the consumer. \n" +
      "9.4. If the consumer has opted for a more expensive method of delivery than the cheapest standard delivery, the entrepreneur does not have to reimburse the additional costs for the more expensive method.",
  tos19: "Article 10 - Termination",
  tos20:
      "10.1. The customer can cancel the subscription (which has been entered into for an indefinite period) at any time by sending an e-mail to hoi@tealivered.nl and cancel it via the customer account on the website. A maximum notice period of one month applies. .\n" +
      "10.2. The customer can terminate the agreement that has been entered into for a definite period of time after the first delivery by sending an e-mail to hoi@tealivered.nl stating the reason. Refund of the purchase amount will be made if cancellation has taken place within the cooling-off period of 14 days stated. in article 6 of the customer.",
  tos21: "Article 11 - Complaints procedure",
  tos22:
      "11.1. Complaints about the product, service, or delivery must be shared digitally with the entrepreneur. Mail your complaint to hoi@tealivered.nl and put the word 'complaint' in the subject line. \n" +
      "11.2. Your complaint will be considered and you will be informed by Tealivered within 14 days from the date of receipt with acknowledgment of receipt and indication for a more detailed answer if necessary. \n" +
      "11.3. Complaints about the implementation of the agreement must be submitted fully and clearly described to the entrepreneur within a reasonable time after the consumer has discovered the defects. These can be submitted digitally via hoi@tealivered.nl",
  tos23: "Artikel 12 - Disputes",
  tos24:
      "12.1. Agreements between the entrepreneur and the consumer to which these general terms and conditions apply are exclusively governed by Dutch law.",
  tos25: "Article 13 - Warranty and Liability",

  tos26:
      "13.1. We only guarantee that the products supplied by us are suitable for the purpose for which they are intended on our information provided and that these products comply with the statutory obligations and regulations that apply to that purpose of use. \n" +
      "13.2. Application of this warranty requires that the consumer makes a written appeal to us. ",
  tos27: "Article 14 - Amendment of the terms and conditions Tealivered",
  tos28:
      "14.1. The most recent version of our terms and conditions can be found on the website tealivered.nl. \n" +
      "14.2. We reserve the right to update, replace or change these terms and conditions in case of changes to our website or to our services and/or products. It is recommended that you consult this page regularly.",
  tos29: " ",

  privacy: "Privacy statement",
  privacy1:
      "Tealivered, located at De Brink 112, 9723AM in Groningen, is responsible for the processing of personal data as shown in this privacy statement.",
  privacy2: "Contact information: ",
  privacy3: "https://www.tealivered.nl",
  privacy4: "De Brink 112, 9723AM in Groningen",
  privacy5: "0646923202",
  privacy6: "Personal data we process",
  privacy7:
      "Tealivered processes your personal data because you use our services and/or because you provide this data to us yourself. Below you will find an overview of the personal data that we process: ",
  privacy8: "- First and last name",
  privacy9: "- Address details",
  privacy10: "- E-mail address",
  privacy11: "- Bank account number",
  privacy12: "Special and/or sensitive personal data that we process",
  privacy13:
      "Our website and/or service does not intend to collect data about website visitors who are younger than 16 years old. Unless they have permission from a parent or guardian. However, we cannot check whether a visitor is older than 16. We therefore advise parents to be involved in the online activities of their children, in order to prevent data about children being collected without parental consent If you are convinced that we have collected personal data about a minor without that consent, please contact us at hoi@tealivered.nl, on request we will remove this information.",
  privacy14:
      "For what purpose and on what basis we process personal data Tealivered processes your personal data for the following purposes: ",
  privacy15: "- Handling your payment",
  privacy16: "- Sending our newsletter and/or advertising brochure",
  privacy17:
      "- To be able to call or e-mail you if this is necessary to be able to carry out our services",
  privacy18: "- Informing you about changes to our services and products",
  privacy19: "- Offer you the possibility to create an account",
  privacy20: "- To deliver goods and services to you",
  privacy21:
      "- Tealivered also processes personal data if we are legally obliged to do so, such as data that we need for our tax return.",
  privacy22: "Automated decision making",
  privacy23:
      "Tealivered takes responsibility based on automated processing about matters that can have (significant) consequences for people. These are decisions that are taken by computer programs or systems, without involving a person (for example an employee of Tealivered). Tealivered uses the following computer programs or systems: ",
  privacy24: "- Own website (fully managed)",
  privacy25: "- Hostnet for hosting your own website",
  privacy26: "- includes a domain name security system extension",
  privacy27: "- Payment transactions via Mollie",
  privacy28: "- Microsoft internal organization software",
  privacy29: "Retention period",
  privacy30:
      "Tealivered does not store your personal data for longer than is strictly necessary to realize the purposes for which your data is collected. We use the following retention periods for the following (categories) of personal data: ",
  privacy31: "- until you delete your account",
  privacy32: "- E-mail address: until you request the deletion of your data",
  privacy33:
      "- Shipping/address details/bank details: for 7 years after making your account inactive",
  privacy34: "Sharing personal data with third parties",
  privacy35:
      "Tealivered does not sell your data to third parties and will only provide it if this is necessary for the execution of our agreement with you or to comply with a legal obligation. We conclude a processor agreement with companies that process your data on our behalf. ensure the same level of security and confidentiality of your data. Tealivered remains responsible for these processing operations.",
  privacy36: "Cookies, or similar techniques, that we use ",
  privacy37:
      "Tealivered uses functional, analytical and tracking cookies. A cookie is a small text file that is stored in the browser of your computer, tablet or smartphone when you first visit this website. Tealivered uses cookies with a purely technical functionality. These ensure that the website works properly and that, for example, your preferences are remembered.These cookies are also used to make the website work properly and to optimize it.We also place cookies that track your surfing behavior so that we can offer customized content and advertisements. ",
  privacy38:
      "We already informed you about these cookies on your first visit to our website and we asked for your permission to place them.",
  privacy39:
      "You can opt out of cookies by setting your internet browser so that it no longer stores cookies. In addition, you can also delete all information that was previously stored via the settings of your browser.",
  privacy40:
      "For an explanation, see: https://veiliginternetten.nl/themes/gedrag/cookies-wat-zijn-het-en-wat-doe-ik-ermee/ ",
  privacy41:
      "Cookies are also placed on this website by third parties. These are, for example, advertisers and/or social media companies. Below is an overview: ",
  privacy42: "[Example: ",
  privacy43: "Cookie: Google Analytics",
  privacy44: "Name: _utma ",
  privacy45: "Function: Analytical cookie that measures website visit",
  privacy46: "Retention period: 2 years] ",
  privacy47: "View, modify or delete data ",
  privacy48:
      "You have the right to view, correct or delete your personal data. You can do this yourself via the personal settings of your account. You also have the right to withdraw or object to your consent to the data processing. against the processing of your personal data by our company and you have the right to data portability, which means that you can submit a request to us to send the personal data we have about you in a computer file to you or another organization mentioned by you. send. ",
  privacy49:
      "If you want to make use of your right to object and/or right to data portability or if you have other questions/comments about data processing, please send a specified request to hoi@tealivered.nl.",
  privacy50:
      "To ensure that the request for access has been made by you, we ask you to enclose a copy of your proof of identity with the request. Make your passport photo, MRZ (machine readable zone, the strip with numbers at the bottom) in this copy. the passport), passport number and Citizen Service Number (BSN) in black. This is to protect your privacy. Tealivered will respond to your request as soon as possible, but in any case within four weeks.",
  privacy51:
      "Tealivered would also like to point out that you have the option of submitting a complaint to the national supervisory authority, the Dutch Data Protection Authority. You can do this via the following link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de -authority personal data/tip-ons",
  privacy52: "How we protect personal data",
  privacy53:
      "Tealivered takes the protection of your data seriously and takes appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized modification. If you feel that your data is not properly secured or there are indications of misuse, please contact us at hoi@tealivered.nl Tealivered has taken the following measures to protect your personal data: ",
  privacy54: "- Hostnet",
  privacy55:
      "- domain name security system. This gives your website visit a digital signature so that you always end up at our website. This prevents hackers from taking you to a fake Tealivered fake site when you visit our website.",
  privacy56: "- Security software, such as virus scanner and firewall.",
  privacy57: "- Multifactor authenticate for our internal organization",
  privacy58:
      "- TLS (formerly SSL) We send your data via a secure internet connection. You can see this by the address bar 'https' and the padlock in the address bar. ",
  privacy59: "",
  privacy60: "",

  about1: "About",
  about2: "Origination",
  about3:
      "One evening in 2021 the idea was born. Out of a need to have tasty tea at home, the question arose: why does no one deliver good teas at home? Then I’ won't forget to buy it again and that's how we got started. That very evening we, Bas Jetten, Cees bij de Leij and Zenash Pouwels, shared and discussed the first ideas about pure and good tea for home delivery, a.k.a.Tealivered. ",
  about4: "Tealivered",
  about5:
      "At Tealivered, we believe that tea is extra tasty when it is loose leaf tea. So, the tea leafs then has space to release all its flavor. Therefore, you can taste the pure taste of tea. Based on our vision, we want to deliver qualitea to every household, so you always get to experience pure tea.",
  about6:
      "We strive to become a completely sustainable company. Tea is a natural product and we think it is important to keep it all natural. All our packaging is biodegradable. You can therefore safely throw your Tealivered tea into your compost bin.",
  about7: "Tea offers",
  about8:
      "Besides pure tea we offer ease. Through our select option of organic tea flavors you will experience no stress to select your favorites. For variety, there is regularly a temporary tea flavor in our tea range: De Thee. For those who like to change it up and experiment every now and then. You choose your subscription and we take care of your monthly stock. ",
  about9: "Tealivered for home or office ",
  about10:
      "If you have any questions about our tea or who we are, we can be reached via hoi@tealivered.nl",
  about11:
      "Would you like Tealivered for business use? Please contact hoi@tealivered.nl to discuss the possibilities. You choose the flavor and we take care of your stock. ",

  qualitea1: "Qualitea? ",
  qualitea2:
      "You should enjoy every cup of Tealivered. Our teas are simple with a touch of specialty. Sometimes you will taste more spices and other times the tea will be fruitier. In the core always pure loose leaf tea or herbs. So, we can deliver you qualitea, and you get to enjoy the true taste of tea.",
};
