import React, { useEffect } from "react";

export const Header = ({ title, subtitle, id }) => {
  return (
    <div className="header header-animation" id={id}>
      <div className="left-header"></div>
      <div className="right-header"></div>
      <div className="t-banner">
        <p className="t-text header-text ">Tea</p>
        <p className="header-text">livered</p>
        <div></div>
      </div>
      {/*<img src={Banner} alt="banner" width="100%" />*/}
    </div>
  );
};
