import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Product } from "./product";
import { listProducts } from "../actions/productActions";

export const SelectFlavors = ({
  subscriptionType,
  flavors,
  handleSetFlavors,
}) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  const [selectedFlavor, setSelectedFlavor] = useState(flavors || []);
  const [maxFlavor, setMaxFlavors] = useState(0);

  const updateFlavors = (newFlavors) => {
    handleSetFlavors(newFlavors); // Update the flavors using setFlavors
    setSelectedFlavor(newFlavors);
  };
  const maxFlavors = (sub) => {
    switch (sub) {
      case 1:
      case 2:
        setMaxFlavors(2);
        break;
      case 3:
        setMaxFlavors(3);
        break;
      case 4:
      case 5:
        setMaxFlavors(4);
        break;
      default:
        setMaxFlavors(0);
    }
  };

  useEffect(() => {
    dispatch(listProducts());
    maxFlavors(subscriptionType);
  }, [dispatch]);

  const selectFlavors = (event, value) => {
    const classEl = event.currentTarget.classList;

    switch (true) {
      case selectedFlavor.length == maxFlavor && classEl.contains("active"):
      default:
        classEl.remove("active");
        updateFlavors(
          selectedFlavor.filter((arr) => {
            if (arr !== value) {
              return true;
            }
          })
        );
        break;
      case selectedFlavor.length >= maxFlavor:
        console.log("You already reached your max flavors");
        break;
      case !classEl.contains("active"):
        classEl.add("active");
        updateFlavors((arr) => [...arr, `${value}`]);
        break;
    }
  };

  return (
    <>
      <div className={"flavors"}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <p>Error loading data</p>
        ) : (
          <>
            {/*<div className="flavors">*/}
            {products.map((product, index) => (
              <Product
                key={product._id}
                product={product}
                flavors={flavors}
                clickProduct={selectFlavors}
              ></Product>
            ))}
            {/*</div>*/}
          </>
        )}
      </div>
    </>
  );
};
