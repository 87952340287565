import React from "react";
import {useTranslation} from "react-i18next";

export const NotFound = () => {
    const { t } = useTranslation();

    return (
      <div className="sectiondiv">
        {t("notfound")}
      </div>
    )
}
