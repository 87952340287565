import Navbar from "../../components/Navbar";
import React from "react";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";

export const Completedorder = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="App">
        <Navbar />

        <div className="sectionsub">
          <div className={"section-paragraph"}>
            <h2>{t("completedorder")} {id}</h2>
            <p>{t("completedorder2")}</p>

          <div className={"button-holder"}>
            <button className="continue" onClick={()=> navigate("../myaccount")}>
              {t("myAccount")}
            </button>
          </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
