import React from "react";
import Axios from "axios";

import {
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
} from "../constants/userConstants";
import { useSelector } from "react-redux";

export const register = (name, email, password, confirmPassword) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { email, password, confirmPassword } });
  try {
    const { data } = await Axios.post("/api/users/register", {
      name,
      email,
      password,
      confirmPassword
    });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post("/api/users/signin", { email, password });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });

    if (data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {


  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
    payload: { email },
  });

  try {
    const { data } = await Axios.get(`/api/users/forgotpassword`, {
      headers: {
        email: email,
      },
    });
    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPassword =
  (id, token, password, passwordconfirm) => async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
      payload: { password, passwordconfirm },
    });

    try {
      const { data } = await Axios.post(
        `/api/users/reset-password/${id}/${token}`,
        { password, passwordconfirm }
      );

      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const signout = (useNavigate) => (dispatch) => {
  const navigate = useNavigate;
  localStorage.removeItem("userInfo");

  dispatch({ type: USER_SIGNOUT });

  if (window.location.pathname !== "/") {
    navigate("/");
  }
};

export const getUserInfo = () => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  if (isJSON(localStorage.getItem("userInfo"))) {
    return JSON.parse(localStorage.getItem("userInfo"));
  }

  function isJSON(str) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }
};
