import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { CircularProgress } from "@material-ui/core/index";
import { Product } from "../../components/product";
import { useNavigate } from "react-router-dom/index";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../actions/productActions";
import { useTranslation } from "react-i18next";
import SubscriptionsSelect from "../../components/SubscriptionsSelect";
import {
  detailsSubscription,
} from "../../actions/subscriptionActions";

export const Order = () => {
  const { t } = useTranslation();
  const [sub, setSub] = useState(0);
  const [subActive, setSubActive] = useState(false);
  const [flavours, setFlavours] = useState([]);
  const [maxFlavour, setMaxFlavours] = useState(0);
  const [bagged, setBagged] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productListState = useSelector((state) => state.productList);
  const { loading: productLoading, error: productError, products } = productListState;

  const subscriptionInfoState = useSelector((state) => state.subscription);
  const { loading: subscriptionLoading, error: subscriptionError, subscription } = subscriptionInfoState;
  
  useEffect(() => {
      dispatch(detailsSubscription());
  }, []);
  
  useEffect(() => {
    if (
      subscription &&
      subscription.status === "paid" &&
      subscription.active === true
    ) {
      setSubActive(true);
    }
  }, [subscription]);

  const subscriptionSelected = {
    name: "order",
    payload: {
      subscription: sub,
      flavors: flavours,
      bagged: bagged,
      active: false,
    },
  };
  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 3, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];

  useEffect(() => {
    dispatch(listProducts());
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [dispatch]);

  function selectSub(sub) {
    const activeFlavours = document.querySelectorAll(".productBanner.active");
    activeFlavours.forEach((flavour) => {
      flavour.classList.remove("active");
    });

    setSub(sub);
    maxFlavours(sub);
    setFlavours([]);

    const options = document.getElementsByClassName("sub-column");

    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }

    const active = options[sub - 1];
    active.classList.add("active");
  }

  function selectFlavors(event, value) {
    const classEl = event.currentTarget.classList;

    switch (true) {
      case flavours.length == maxFlavour && classEl.contains("active"):
      default:
        classEl.remove("active");
        setFlavours(
          flavours.filter((arr) => {
            if (arr !== value) {
              return true;
            }
          })
        );
        break;
      case flavours.length >= maxFlavour:
        console.log("You already reached your max flavours");
        break;
      case !classEl.contains("active"):
        classEl.add("active");
        setFlavours((arr) => [...arr, `${value}`]);
        break;
    }
  }

  function maxFlavours(sub) {
    switch (sub) {
      case 1:
      case 2:
        setMaxFlavours(2);
        break;
      case 3:
        setMaxFlavours(3);
        break;
      case 4:
      case 5:
        setMaxFlavours(4);
        break;
      default:
        setMaxFlavours(0);
    }
  }

  function continueButton() {
    localStorage.setItem(
      "order",
      JSON.stringify({
        subscription: sub,
        flavors: flavours,
        bagged: bagged,
      })
    );

    navigate("/shipping");
  }

  return (
    <>
      <div className="App">
        <Navbar />
        <div className="sectionsub">
          <h1>{t("order1")}</h1>

          {subActive ? (<>
            <p>
              {t("orderActive")}
            </p>

            <div className={"button-holder"}>
              <button className="continue" onClick={()=> navigate("../myaccount")}>
                {t("myAccount")}
              </button>
            </div>
          </>
          ) : (
            <>
              <h2>{t("order2")}</h2>
              <SubscriptionsSelect handleClick={selectSub} />

              {sub == 0 ? (
                <></>
              ) : (
                <>
                  <h2>{t("order3")}</h2>
                  {productLoading ? (
                    <CircularProgress />
                  ) : productError ? (
                    <p>Error loading data</p>
                  ) : (
                    <>
                      <div className="flavors">
                        {products.map((product, index) => (
                          <Product
                            key={product._id}
                            product={product}
                            clickProduct={selectFlavors}
                          ></Product>
                        ))}
                      </div>
                    </>
                  )}

                  {flavours.length > 0 ? (
                    <>
                      <div className={"button-holder"}>
                        <button
                          className="continue"
                          onClick={() => continueButton()}
                        >
                          {t("order5")}
                        </button>
                      </div>
                    </>
                  ) : undefined}
                </>
              )}
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};
