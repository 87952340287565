import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { detailsSubscription } from "../../actions/subscriptionActions";
import { useTranslation } from "react-i18next";
import {updateFlavors, updateShipping} from "../../actions/orderActions";

export const MyAddress = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState("disabled");
  const subscriptionInfo = useSelector((state) => state.subscription);
  const { loading, error, subscription } = subscriptionInfo;

  const [fullName, setFullName] = useState(
    !loading && subscription ? subscription.name : null
  );
  const [address, setAddress] = useState(
    !loading && subscription ? subscription.address : null
  );
  const [city, setCity] = useState(
    !loading && subscription ? subscription.city : null
  );
  const [postalCode, setPostalCode] = useState(
    !loading && subscription ? subscription.postalCode : null
  );
  useEffect(() => {
    dispatch(detailsSubscription());
    // setShippingState(subscription)
  }, [dispatch]);

  function setShippingState(subscription) {
    setFullName(subscription.name);
    setAddress(subscription.address);
    setCity(subscription.city);
    setPostalCode(subscription.postalCode);
  }

  function editShipping(e) {
    if (disabled === "disabled") {
      setDisabled("");
    } else {
      setDisabled("disabled");
    }
  }

  const submitAddress = async (e) => {
    e.preventDefault();

    const shippingAddress = {
      name: fullName,
      address: address,
      city: city,
      postalCode: postalCode,
    };

    dispatch(updateFlavors(shippingAddress));
  };

  return (
    <>
      <div className="account-overview">
        <h2>{t("myAddress")}</h2>
        <form className="form">
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <p>{t(error)}</p>
          ) : subscription ? (
            <fieldset disabled={disabled} className="account-form-fieldset">
              <div>
                <label htmlFor="fullName">{t("myaddress1")}</label>
                <input
                  type="text"
                  id="fullName"
                  placeholder={t("myaddress1")}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                ></input>
              </div>
              <div>
                <label htmlFor="address">{t("myaddress2")}</label>
                <input
                  type="text"
                  id="address"
                  placeholder={t("myaddress2")}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                ></input>
              </div>
              <div>
                <label htmlFor="city">{t("myaddress3")}</label>
                <input
                  type="text"
                  id="city"
                  placeholder={t("myaddress3")}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                ></input>
              </div>
              <div>
                <label htmlFor="postalCode">{t("myaddress4")}</label>
                <input
                  type="text"
                  id="postalCode"
                  placeholder={t("myaddress4")}
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  required
                ></input>
              </div>
            </fieldset>
          ) : null}
        </form>
        <div className={"button-holder"}>
          {error ? null : subscription ? (
            <>
              <button className="edit" onClick={editShipping}>
                {t("edit")}
              </button>
              <button className={"save " + disabled} onClick={submitAddress}>
                {t("save")}
              </button>{" "}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
