import React, { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../assets/img/Logo.svg";
import User from "../assets/icons/General/User.svg";
import Burgermenu from "../assets/icons/Text/Menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, signout } from "../actions/userActions";
import { useTranslation } from "react-i18next";
import "../translations/i18n.js";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [language, setLanguage] = useState("nl");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = getUserInfo();

  const signoutHandler = () => {
    dispatch(signout(navigate));
  };

  window.addEventListener("scroll", function () {
    const navbar = document.querySelector(".navbar");
    if (window.scrollY === 0) {
      navbar.classList.remove("scrolled");
    } else {
      navbar.classList.add("scrolled");
    }
  });

  function languagechanger() {
    if (language === "en") {
      setLanguage("nl");
      const nlelement = document.getElementsByClassName("nl")[0];
      nlelement.classList.add("active");

      const enelement = document.getElementsByClassName("en")[0];
      enelement.classList.remove("active");
    } else {
      setLanguage("en");

      const nlelement = document.getElementsByClassName("nl")[0];
      nlelement.classList.remove("active");

      const enelement = document.getElementsByClassName("en")[0];
      enelement.classList.add("active");
    }

    i18n.changeLanguage(language);
  }

  function openMenu() {
    const menu = document.getElementsByClassName("page-sliders")[0];

    if (!menu.classList.contains("active")) {
      menu.classList.add("active");
    } else {
      menu.classList.remove("active");
    }
  }

  function navigateHome() {
    navigate("/");
  }

  function scrollToTop() {
    scroll.scrollToTop();
  }

  return (
    <>
      <nav className="navbar" id="navbar">
        <ul className="nav-items">
          <li className="nav-item language-button">
            <button onClick={languagechanger}>
              <div className="languagetext">
                <p className="nl"> NL </p> <p>|</p>{" "}
                <p className="en active"> EN </p>
              </div>
            </button>
          </li>
          {window.location.pathname !== "/" ? (
            <></>
          ) : (
            <>
              <li className="home-item burgermenu" onClick={openMenu}>
                <img src={Burgermenu} alt="burgermenu" width={"40px"} />
              </li>
            </>
          )}
          <li className="home-item" onClick={navigateHome}>
            <img src={Logo} alt="logo" width="40px" onClick={scrollToTop} />
          </li>
        </ul>

        <ul className="nav-items">
          <li className="nav-item nav-icon">
            {userInfo ? (
              <div className="dropdown">
                <Link to="#">
                  ( {userInfo.name} )<i className="fa fa-caret-down"></i>{" "}
                  <img src={User} alt="User" height="30px" />
                </Link>
                <ul className="dropdown-content">
                  <li>
                    <RouterLink to="/myaccount">{t("userprofile")}</RouterLink>
                  </li>
                  <li>
                    <Link to="#signout" onClick={signoutHandler}>
                      {t("signout")}
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              <RouterLink className="nav-link" to="/login">
                Login
                <img src={User} alt="User" height="30px" />
              </RouterLink>
            )}
          </li>

          {window.location.pathname !== "/" ? (
            <></>
          ) : (
            <>
              <div className={"page-sliders"}>
                <li className="nav-item">
                  <Link
                    activeClass="active"
                    to="Subscriptions"
                    spy={true}
                    smooth={true}
                    offset={-170}
                    duration={600}
                  >
                    {t("subs")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    activeClass="active"
                    to="Steps"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={600}
                  >
                    {t("hwork")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    activeClass="active"
                    to="Ourteas"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={600}
                  >
                    {t("ourtea")}
                  </Link>
                </li>
              </div>
            </>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
