import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import { useLocation, useNavigate } from "react-router-dom/index";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import Warning from "../../assets/icons/Code/Warning-2.svg";
import Eye from "../../assets/icons/General/Visible.svg";
import Hidden from "../../assets/icons/General/Hidden.svg";

export const Signin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const [showpassword, setShowpassword] = React.useState("password");
  const eyeIcon = showpassword === "password" ? Eye : Hidden;

  localStorage.setItem("userInfo", userInfo);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  };

  let route = "/register";

  if (location.search === "?redirect=/shipping") {
    route = "register?redirect=/shipping";
  }

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    if (userInfo && location.state) {
      navigate(location.state.from);
    } else if (userInfo) {
      navigate("/");
    }
  });

  const newAccount = (e) => {
    if (location.state) {
      navigate("/register", { state: { from: location.state.from } });
    } else {
      navigate("/register");
    }
  };

  const toggleShowPassword = () => {
    if (showpassword === "password") {
      setShowpassword("text");
    } else {
      setShowpassword("password");
    }
  };

  const navTo = () => {
    navigate("/forgotpassword");
  };

  return (
    <>
      <div className="App">
        <Navbar />

        <div className="sectionsub">
          <form className="form" onSubmit={submitHandler}>
            <div>
              <h1>{t("login4")}</h1>
            </div>
            {loading && <LoadingBox></LoadingBox>}
            {error && (
              <MessageBox variant="danger">
                <label className="notification warning">
                  <img src={Warning} alt={""} /> {t(error)}
                </label>
              </MessageBox>
            )}

            <div>
              <label htmlFor="email">{t("register6")}</label>

              <input
                type="email"
                id="email"
                placeholder={t("register6")}
                required
                onChange={(e) => setEmail(e.target.value)}
              ></input>

              <label htmlFor="password">{t("register7")}</label>

              <div className="password-container">
                <input
                  type={showpassword}
                  id="password"
                  placeholder={t("register7")}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
                <img
                  onClick={toggleShowPassword}
                  className={"password-eye"}
                  src={eyeIcon}
                  alt={""}
                />
              </div>
            </div>
            <div className={"button-holder"}>
              <button className="primary" type="submit">
                {t("login3")}
              </button>
              <button className="secondary" onClick={navTo}>
                {t("login5")}
              </button>
            </div>

            <div>
              <div>
                {t("login1")}
                <a onClick={newAccount} className={"link"}>
                  {" "}
                  {t("login2")}
                </a>
              </div>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </>
  );
};
