import React, {useEffect} from "react";
import { Footer } from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";

export const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  });

  return (
    <>
      <div className="App">
        <Navbar />

        <div className="sectionsub">
          <div className={"section-paragraph"}>
            <h1>{t("about1")} </h1>
            <label>{t("about2")}</label>
            <p>{t("about3")}</p>
            <label>{t("about4")}</label>
            <p>{t("about5")}</p>
            <p>{t("about6")}</p>
            <label>{t("about7")}</label>
            <p>{t("about8")}</p>
            <label>{t("about9")}</label>
            <p>{t("about10")}</p>
            <p>{t("about11")}</p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
