import React, {useEffect} from "react";
import { Footer } from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";

export const Qualitea = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  });

  return (
    <>
      <div className="App">
        <Navbar />

        <div className="sectionsub">
          <div className={"section-paragraph"}>
            <h1>{t("qualitea1")} </h1>
            <p>{t("qualitea2")}</p>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
