import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import { useLocation, useNavigate } from "react-router-dom/index";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/userActions";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import Warning from "../../assets/icons/Code/Warning-2.svg";

export const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const redirect = location.state ? location.state.from : '/';

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error } = userRegister;

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(name, email, password, confirmPassword));
  };

  const Signin = (e) => {
    navigate("/login");
  };

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <>
      <div className="App">
        <Navbar />

        <div className="content-margin">
          <div>
            <form className="form" onSubmit={submitHandler}>
              <div>
                <h1>{t("register1")}</h1>
              </div>
              {loading && <LoadingBox></LoadingBox>}
              {error && (
                <MessageBox variant="danger">
                  <label className="notification warning">
                    <img src={Warning} alt={""} /> {t(error)}
                  </label>
                </MessageBox>
              )}
              <div>
                <label htmlFor="name">{t("register5")}</label>
                <input
                  type="text"
                  id="name"
                  placeholder={t("register5")}
                  required
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor="email">{t("register6")}</label>
                <input
                  type="email"
                  id="email"
                  placeholder={t("register6")}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor="password">{t("register7")}</label>
                <input
                  type="password"
                  id="password"
                  placeholder={t("register7")}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor="confirmPassword">{t("register8")}</label>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder={t("register8")}
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></input>
              </div>
              <div>
                <button className="primary" type="submit">
                  {t("register2")}
                </button>
              </div>
              <div>
                <div>
                  {t("register3")}
                  <a onClick={Signin} className={"link"}>
                    {t("register4")}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
