import Axios from "axios";
import {
  FLAVORS_UPDATE_FAIL,
  FLAVORS_UPDATE_REQUEST,
  FLAVORS_UPDATE_SUCCESS,
  INVOICE_FAIL,
  INVOICE_REQUEST,
  INVOICE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  PAYMENT_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  SHIPPING_UPDATE_FAIL,
  SHIPPING_UPDATE_REQUEST,
  SHIPPING_UPDATE_SUCCESS,
} from "../constants/orderConstants";

const listOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    });
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const { data } = await Axios.get("api/orders/adminview", {
      headers: {
        Authorization: " Bearer " + userInfo.token,
      },
    });

    dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ORDER_LIST_FAIL, payload: error.message });
  }
};

const createOrder = (order, shippingAddress) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
      payload: { ...order, ...shippingAddress },
    });
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const {
      data: { data: newOrderCreated },
    } = await Axios.post(
      "/api/orders/create",
      {
        order,
        shippingAddress,
      },
      {
        headers: {
          Authorization: " Bearer " + userInfo.token,
        },
      }
    )
      .then(function (response) {
        let config = {
          method: "get",
          url: "api/orders/payment",
          headers: { Authorization: " Bearer " + userInfo.token },
        };

        Axios(config)
          .then(function (response) {
            window.location.href = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: newOrderCreated });
  } catch (error) {
    dispatch({ type: ORDER_CREATE_FAIL, payload: error.message });
  }
};

const createPayment = () => async (dispatch) => {
  dispatch({
    type: PAYMENT_REQUEST,
  });
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  try {
    const { data } = await Axios.get("api/orders/payment", {
      headers: {
        Authorization: " Bearer " + userInfo.token,
      },
    });
    dispatch({ type: PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PAYMENT_FAIL, payload: error.message });
  }
};

const updateShipping = (shippingAddress) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_UPDATE_REQUEST,
      payload: { ...shippingAddress },
    });
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const {
      data: { data: updatedShipping },
    } = await Axios.post(
      "/api/orders/updateShipping",
      {
        shippingAddress,
      },
      {
        headers: {
          Authorization: " Bearer " + userInfo.token,
        },
      }
    ).then(function () {
      window.location.reload(false);
    });
    dispatch({ type: SHIPPING_UPDATE_SUCCESS, payload: updatedShipping });
  } catch (error) {
    dispatch({ type: SHIPPING_UPDATE_FAIL, payload: error.message });
  }
};

const updateFlavors = (updatedFlavors) => async (dispatch) => {
  try {
    dispatch({
      type: FLAVORS_UPDATE_REQUEST,
      payload: [...updatedFlavors],
    });
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const {
      data: { data: updatedFlavorsData },
    } = await Axios.post(
        "/api/orders/updateFlavors",
        {
          flavors: updatedFlavors,
        },
        {
          headers: {
            Authorization: "Bearer " + userInfo.token,
          },
        }
    ).then(function () {
      window.location.reload(false);
    });
    dispatch({ type: FLAVORS_UPDATE_SUCCESS, payload: updatedFlavorsData });
  } catch (error) {
    dispatch({ type: FLAVORS_UPDATE_FAIL, payload: error.message });
  }
};

const getInvoice = (invoice) => async (dispatch) => {
  const invoiceObject = invoice.file_data;

  try {
    dispatch({
      type: INVOICE_REQUEST,
      payload: invoiceObject,
    });

    const response = await Axios.post(
      "api/orders/downloadinvoice",
      {
        invoiceObject,
      },
      {
        responseType: "blob",
      }
    ).then((response) => {
      // const content = response.headers['content-type'];
      // download(response.data, "document.pdf", content)
      window.open(URL.createObjectURL(response.data));
    });

    dispatch({ type: INVOICE_SUCCESS });
  } catch (error) {
    dispatch({ type: INVOICE_FAIL, payload: error.message });
  }
};

export {
  listOrders,
  createOrder,
  updateShipping,
  createPayment,
  getInvoice,
  updateFlavors,
};
