import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tea from "../assets/icons/Food/Tea1.svg"

function SubscriptionsSelect(handleClick) {

  const teaIcon = (
    <img
      src={Tea}
      alt="tea"
      width={"40px"}
    />
  );
  const { t } = useTranslation();

  return (<>
    <div className="content subscriptions-teas">
      <div className={"sub-holder"}>
        <div
          className={"sub-column"}
          onClick={() => {
            handleClick.handleClick(1);
          }}
        >
          <h2 className={"sub-title"}>{t("sub1a")}</h2>

          <div className={"sub-icon"}>{teaIcon}</div>

          <div className={"sub-text"}>{t("sub1b")}</div>

          <div className={"pricetag"}>{t("price1")}</div>
        </div>
        <div
          className={"sub-column"}
          onClick={() => {
            handleClick.handleClick(2);
          }}
        >
          <h2 className={"sub-title"}>{t("sub2a")}</h2>

          <div className={"sub-icon"}>
            {teaIcon}
            {teaIcon}
          </div>

          <div className={"sub-text"}>{t("sub2b")}</div>
          <div className={"pricetag"}>{t("price2")}</div>
        </div>
        <div
          className={"sub-column"}
          onClick={() => {
            handleClick.handleClick(3);
          }}
        >
          <h2 className={"sub-title"}>{t("sub3a")}</h2>

          <div className={"sub-icon"}>
            {teaIcon}
            {teaIcon}
            {teaIcon}
          </div>

          <div className={"sub-text"}>{t("sub3b")}</div>
          <div className={"pricetag"}>{t("price3")}</div>
        </div>

        <div
          className={"sub-column"}
          onClick={() => {
            handleClick.handleClick(4);
          }}
        >
          <h2 className={"sub-title"}>{t("sub4a")}</h2>

          <div className={"sub-icon"}>
            <div className={"sub-icon-splitter"}>
              {teaIcon}
              {teaIcon}
            </div>
            {teaIcon}
            {teaIcon}
          </div>

          <div className={"sub-text"}>{t("sub4b")}</div>
          <div className={"pricetag"}>{t("price4")}</div>
        </div>

        <div
          className={"sub-column"}
          onClick={() => {
            handleClick.handleClick(5);
          }}
        >
          <h2 className={"sub-title"}>{t("sub5a")}</h2>

          <div className={"sub-icon"}>
            <div className={"sub-icon-splitter"}>
              {teaIcon}
              {teaIcon}
              {teaIcon}
            </div>
            {teaIcon}
            {teaIcon}
          </div>

          <div className={"sub-text"}>{t("sub5b")}</div>
          <div className={"pricetag"}>{t("price5")}</div>
        </div>
      </div>
    </div>
      </>

  );
}

export default SubscriptionsSelect;
