import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Home,
  Signin,
  AccountPage,
  Register,
  NotFound,
  Shipping,
  Order,
  Completedorder,
  Forgotpassword,
  Resetpassword,
  PrivacyDeclaration,
  TermsAndConditions,
  Faq,
  SubscriptionsInfo,
  Qualitea,
  About,
} from "./pages";
import ScrollToTop from "./components/ScrollToTop";

const paths = {
  home: "/",
  signin: "/login",
  register: "/register",
  forgotpassword: "/forgotpassword",
  resetpassword: "/resetpassword/:id/:token",
  myaccount: "/myaccount",
  shipping: "/shipping",
  order: "/order",
  completedorder: "/completedorder/:id",
  faq: "/faq",
  privacydeclaration: "/privacydeclaration",
  termsandconditions: "/termsandconditions",
  about: "/about",
  qualitea: "/qualitea",
  subscriptioninfo: "/subscriptioninfo",
};

export const routes = {
  ...paths,
};

const Router = () => {
  return (
    <Routes>
      <Route path={paths.home} exact element={<Home />} />
      <Route path={paths.signin} exact element={<Signin />} />
      <Route path={paths.register} exact element={<Register />} />
      <Route path={paths.forgotpassword} exact element={<Forgotpassword />} />
      <Route path={paths.resetpassword} exact element={<Resetpassword />} />
      <Route path={paths.myaccount} exact element={<AccountPage />} />
      <Route path={paths.shipping} exact element={<Shipping />} />
      <Route path={paths.order} exact element={<Order />} />
      <Route path={paths.completedorder} exact element={<Completedorder />} />

      <Route path={paths.about} exact element={<About />} />
      <Route path={paths.qualitea} exact element={<Qualitea />} />
      <Route
        path={paths.subscriptioninfo}
        exact
        element={<SubscriptionsInfo />}
      />

      <Route path={paths.faq} exact element={<Faq />} />
      <Route
        path={paths.privacydeclaration}
        exact
        element={<PrivacyDeclaration />}
      />
      <Route
        path={paths.termsandconditions}
        exact
        element={<TermsAndConditions />}
      />

      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
};

export default Router;
