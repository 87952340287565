export const ORDER_CREATE_REQUEST = "ORDER_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_FAIL";
export const ORDER_SAVE_SHIPPING = "ORDER_SAVE_SHIPPING";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";

export const SHIPPING_UPDATE_REQUEST = "SHIPPING_UPDATE_REQUEST";
export const SHIPPING_UPDATE_SUCCESS = "SHIPPING_UPDATE_SUCCESS";
export const SHIPPING_UPDATE_FAIL = "SHIPPING_UPDATE_FAIL";

export const FLAVORS_UPDATE_REQUEST = "FLAVORS_UPDATE_REQUEST";
export const FLAVORS_UPDATE_SUCCESS = "FLAVORS_UPDATE_SUCCESS";
export const FLAVORS_UPDATE_FAIL = "FLAVORS_UPDATE_FAIL";

export const INVOICE_REQUEST = "INVOICE_REQUEST";
export const INVOICE_SUCCESS = "INVOICE_SUCCESS";
export const INVOICE_FAIL = "INVOICE_FAIL";
