import {
    PAYMENT_HISTORY_FAIL,
    PAYMENT_HISTORY_REQUEST,
    PAYMENT_HISTORY_SUCCESS
} from "../constants/historyConstants.js";

export const historyReducer = (state = { orderItem: [] }, action) => {
    switch (action.type) {
case
    PAYMENT_HISTORY_FAIL:
        return {loading: false, error: action.payload};
case
    PAYMENT_HISTORY_REQUEST:
        return {loading: true};
case
    PAYMENT_HISTORY_SUCCESS:
        return {loading: false, historyPayments: action.payload};
        default:
            return state}

}