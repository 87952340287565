import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { MyAddress } from "./MyAddress.js";
import { MySubscription } from "./MySubscription";
import { MyHistory } from "./MyHistory";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import { Adminview } from "./Adminview";
import { useTranslation } from "react-i18next";
import { CustomizeFlavours } from "./CustomizeFlavours";

import Layout from "../../assets/icons/Layout/Layout-4-blocks.svg";
import Edit from "../../assets/icons/General/Edit.svg";
import Archive from "../../assets/icons/Communication/Archive.svg";
import Spy from "../../assets/icons/Code/Spy.svg";
import Door from "../../assets/icons/Home/Door-open.svg";

export const AccountPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [activeId, setActiveId] = useState(1);

  const changeContent = (x) => {
    setPage(x);
  };

  const values = [
    { id: 1, icon: Layout, text: t("accpage1"), show: true },
    { id: 2, icon: Edit, text: t("accpage2"), show: true },
    { id: 3, icon: Archive, text: t("accpage3"), show: true },
    { id: 4, icon: Spy, text: t("accpage4"), show: userInfo.isAdmin },
    { id: 6, icon: Door, text: t("accpage5"), show: true },
  ];

  function signoutHandler() {
    dispatch(signout(navigate));
  }

  useEffect(() => {
    // dispatch(detailsSubscription());
  }, [dispatch]);

  function renderSwitch() {
    switch (page) {
      case 1:
        return <MySubscription setPage={changeContent} />;
        break;
      case 2:
        return <MyAddress />;
        break;
      case 3:
        return <MyHistory />;
        break;
      case 4:
        return <Adminview />;
        break;
      case 5:
        return <CustomizeFlavours setPage={changeContent} />;
        break;
      case 6:
        signoutHandler();
        break;
    }
  }

  return (
    <>
      <div className="App">
        <Navbar />
        <div className="myaccount-content">
          <div className="account-menu">
            <ul>
              {values.map((val) =>
                val.show ? (
                  <>
                    <li
                      className={activeId === val.id ? "active" : null}
                      onClick={() => {
                        setActiveId(val.id);
                        setPage(val.id);
                      }}
                    >
                      <img className={"menu-icon"} src={val.icon} alt={""} />
                      {val.text}
                    </li>{" "}
                  </>
                ) : null
              )}
            </ul>
          </div>
          <div className="myAccountBody">{renderSwitch()}</div>
        </div>
      </div>
    </>
  );
};
