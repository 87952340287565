import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { paymentHistory } from "../../actions/historyActions";
import { CircularProgress } from "@material-ui/core";
import { dateFormatter, subscriptionType } from "../../utils";
import { getInvoice } from "../../actions/orderActions";

export const MyHistory = () => {
  const dispatch = useDispatch();
  const paymentHistoryInfo = useSelector((state) => state.paymentHistory);
  const { loading, error, historyPayments } = paymentHistoryInfo;
  const { t } = useTranslation();

  const downloadInvoice = (invoice) => {
    dispatch(getInvoice(invoice));
  };

  useEffect(() => {
    dispatch(paymentHistory());
  }, [dispatch]);

  return (
    <>
      <h2>{t("accpage3")}</h2>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <p>{t(error)}</p>
      ) : historyPayments ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("history1")}</TableCell>
                <TableCell align="left">{t("history2")}</TableCell>
                <TableCell align="right">{t("history3")}</TableCell>
                <TableCell align="right">{t("history4")}</TableCell>
                {/*<TableCell align="right">{t("history5")}</TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {historyPayments.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {subscriptionType(row.subscription)}
                  </TableCell>
                  <TableCell align="right">
                    {row.flavors.map((flavor, index) => (
                      <label className={"table-label"}>{flavor}</label>
                    ))}
                  </TableCell>
                  <TableCell align="right">
                    {dateFormatter(row.createdAt)}
                  </TableCell>
                  <TableCell align="right">{row.amountpaid}</TableCell>
                  {/*<TableCell align="right">*/}
                  {/*  <img*/}
                  {/*    className={"download"}*/}
                  {/*    onClick={() => downloadInvoice(row.invoice)}*/}
                  {/*    src={Invoice}*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};
