import React, { useEffect } from "react";
import { About } from "./About";
import { Header } from "./Header";
import { Steps } from "./Steps";
import { Ourteas } from "./Ourteas";
import Navbar from "../../components/Navbar";
import "../../css/index.css";
import { Footer } from "../../components/Footer.js";
import { Subscriptions } from "./Subscriptions";
import $ from "jquery";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

export const Home = () => {
  useEffect(() => {
    $(".productBanner").on("click", function (e) {
      e.preventDefault();
      return false;
    });
  });

  const { t } = useTranslation();

  const cookieWindow = {
    left: "auto",
    bottom: "32px",
    right: "32px",
    background: "#000000",
    color: "white",
    position: "fixed",
    width: "30%",
    opacity: "0.9",
    "justify-content": "end",
    "text-align": "justify"
  };

  return (
      <>
        <div className="App">
          <Navbar />
          <Header title="Home" subtitle="test" id="Home" />
          <About title={t("about")} subtitle="test" id="About" />
          <Ourteas title={t("ourtea")} subtitle="test" id="Ourteas" />
          <Steps title={t("hwork")} subtitle="test" id="Steps" />
          <Subscriptions title={t("subs")} subtitle="test" id="Subscriptions" />
          <Footer title="Footer" subtitle="test" id="Footer" />

          <CookieConsent
              buttonText="Accepteren"
              cookieName="myAwesomeCookieName2"
              style={cookieWindow}
              buttonStyle={{ background: "#f59a75", fontSize: "13px" }}
              expires={150}
          >
            Wij gebruiken cookies om het gebruik van onze webwinkel te
            faciliteren. U kunt deze cookies uitzetten via uw browser maar dit kan
            het functioneren van onze website negatief aantasten.
          </CookieConsent>
        </div>
      </>
  );
};
