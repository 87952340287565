import React, {useEffect} from "react";
import Navbar from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";

export const PrivacyDeclaration = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  });

  return (
    <>
      <div className="App">
        <Navbar />

        <div className="sectionsub">
          <div className={"section-paragraph"}>
            <h1>{t("privacy")} </h1>
            <p>{t("privacy1")}</p>
            <p>{t("privacy2")}</p>
            <p>{t("privacy3")}</p>
            <p>{t("privacy4")}</p>
            <p>{t("privacy5")}</p>
            <label>{t("privacy6")}</label>
            <p>{t("privacy7")}</p>
            <p>{t("privacy8")}</p>
            <p>{t("privacy9")}</p>
            <p>{t("privacy10")}</p>
            <p>{t("privacy11")}</p>
            <label>{t("privacy12")}</label>
            <p>{t("privacy13")}</p>
            <p>{t("privacy14")}</p>
            <p>{t("privacy15")}</p>
            <p>{t("privacy16")}</p>
            <p>{t("privacy17")}</p>
            <p>{t("privacy18")}</p>
            <p>{t("privacy19")}</p>
            <p>{t("privacy20")}</p>
            <p>{t("privacy21")}</p>
            <label>{t("privacy22")}</label>
            <p>{t("privacy23")}</p>
            <p>{t("privacy24")}</p>
            <p>{t("privacy25")}</p>
            <p>{t("privacy26")}</p>
            <p>{t("privacy27")}</p>
            <p>{t("privacy28")}</p>
            <label>{t("privacy29")}</label>
            <p>{t("privacy30")}</p>
            <p>{t("privacy31")}</p>
            <p>{t("privacy32")}</p>
            <p>{t("privacy33")}</p>
            <label>{t("privacy34")}</label>
            <p>{t("privacy35")}</p>
            <label>{t("privacy36")}</label>
            <p>{t("privacy37")}</p>
            <p>{t("privacy38")}</p>
            <p>{t("privacy39")}</p>
            <p>{t("privacy40")}</p>
            <p>{t("privacy41")}</p>
            <p>{t("privacy42")}</p>
            <p>{t("privacy43")}</p>
            <p>{t("privacy44")}</p>
            <p>{t("privacy45")}</p>
            <p>{t("privacy46")}</p>
            <label>{t("privacy47")}</label>
            <p>{t("privacy48")}</p>
            <p>{t("privacy49")}</p>
            <p>{t("privacy50")}</p>
            <p>{t("privacy51")}</p>
            <label>{t("privacy52")}</label>
            <p>{t("privacy53")}</p>
            <p>{t("privacy54")}</p>
            <p>{t("privacy55")}</p>
            <p>{t("privacy56")}</p>
            <p>{t("privacy57")}</p>
            <p>{t("privacy58")}</p>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
