import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { listOrders } from "../../actions/orderActions";
import {useTranslation} from "react-i18next";

export const Adminview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderList = useSelector((state) => state.order);
  const { loading, error, products } = orderList;

  useEffect(() => {
    dispatch(listOrders());
  }, [dispatch]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>orderId</TableCell>
              <TableCell align="right">{t("adminview1")}</TableCell>
              <TableCell align="right">{t("adminview2")}</TableCell>
              <TableCell align="left">{t("adminview3")}</TableCell>
              <TableCell align="left">{t("adminview4")}</TableCell>
              <TableCell align="left">{t("adminview5")}</TableCell>
              <TableCell align="left">{t("adminview6")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <p>Error loading data</p>
            ) : products ? (
              <>
                {products.map((order, index) => (
                  <TableRow key={order.orderId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {order.email}
                    </TableCell>
                    <TableCell align="right">{order.name}</TableCell>
                    <TableCell align="right">{order.subscription}</TableCell>
                    <TableCell align="left">
                      {order.flavors.map((flavor, index) => (
                          <label className={"table-label"}>{flavor}</label>
                          ))}
                      </TableCell>
                    <TableCell align="left">{order.createdAt.split("T")[0]}</TableCell>
                    <TableCell align="left">{order.updatedAt.split("T")[0]}</TableCell>
                    <TableCell align="left">
                      <label className={"table-label"}>{order.address}</label>
                      <label className={"table-label"}>{order.city}</label>
                      <label className={"table-label"}>{order.postalCode}</label>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
