import {
  ORDER_SAVE_SHIPPING,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  SHIPPING_UPDATE_REQUEST,
  SHIPPING_UPDATE_SUCCESS,
  SHIPPING_UPDATE_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
  INVOICE_REQUEST,
  INVOICE_SUCCESS,
  INVOICE_FAIL,
  FLAVORS_UPDATE_REQUEST,
  FLAVORS_UPDATE_SUCCESS,
  FLAVORS_UPDATE_FAIL,
} from "../constants/orderConstants";

export const orderReducer = (state = { orderItem: [] }, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CREATE_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_SAVE_SHIPPING:
      return { ...state, shipping: action.payload };

    case ORDER_LIST_REQUEST:
      return { loading: true };
    case ORDER_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };

    case PAYMENT_REQUEST:
      return { loading: true };
    case PAYMENT_SUCCESS:
      return { loading: false, products: action.payload };
    case PAYMENT_FAIL:
      return { loading: false, error: action.payload };

    case SHIPPING_UPDATE_REQUEST:
      return { loading: true };
    case SHIPPING_UPDATE_SUCCESS:
      return { loading: false, products: action.payload };
    case SHIPPING_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case FLAVORS_UPDATE_REQUEST:
      return { loading: true };
    case FLAVORS_UPDATE_SUCCESS:
      return { loading: false, products: action.payload };
    case FLAVORS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case INVOICE_REQUEST:
      return { loading: true };
    case INVOICE_SUCCESS:
      return { loading: false, products: action.payload };
    case INVOICE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
