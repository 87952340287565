import React from "react";

export const TitleUnderline = () => {
  return (
    <>
      <div className="Underline-box right">
        <div className="Underline-box2">
          <div className="Underline-box3"></div>
        </div>
      </div>
    </>
  );
};

export const TitleUnderlineSingle = (color) => {
  return (
    <>
      <div
        style={{ backgroundColor: { color } }}
        className="Underline-box"
      >
      {/*<div className="Underline-box2">*/}
        <div className="Underline-box3"></div>
      {/*</div>*/}
      </div>
    </>
  );
};

export const TitleUnderlineRedGreen = (color) => {
    return (
        <>
            <div
                style={{ backgroundColor: "#6ca392" }}
                className="Underline-box"
            >
                {/*<div className="Underline-box2">*/}
                <div
                    style={{ backgroundColor: "#f59a75" }}
                    className="Underline-box3"></div>
                {/*</div>*/}
            </div>
        </>
    );
};