import {
  SUB_CANCEL_FAIL,
  SUB_CANCEL_REQUEST,
  SUB_CANCEL_SUCCESS,
  SUB_DETAILS_FAIL,
  SUB_DETAILS_REQUEST,
  SUB_DETAILS_SUCCESS, SUB_RESUME_FAIL, SUB_RESUME_REQUEST, SUB_RESUME_SUCCESS,

} from "../constants/subscriptionConstants";

export const subscriptionReducer = (state = { orderItem: [] }, action) => {
  switch (action.type) {
    case SUB_DETAILS_REQUEST:
      return { loading: true };
    case SUB_DETAILS_SUCCESS:
      return { loading: false, subscription: action.payload };
    case SUB_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SUB_CANCEL_REQUEST:
      return { loading: true };
    case SUB_CANCEL_SUCCESS:
      return { loading: false, subscription: action.payload };
    case SUB_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case SUB_RESUME_REQUEST:
      return { loading: true };
    case SUB_RESUME_SUCCESS:
      return { loading: false, subscription: action.payload };
    case SUB_RESUME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
