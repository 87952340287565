import Axios from "axios";
import {
  SUB_CANCEL_FAIL,
  SUB_CANCEL_REQUEST,
  SUB_CANCEL_SUCCESS,
  SUB_DETAILS_FAIL,
  SUB_DETAILS_REQUEST,
  SUB_DETAILS_SUCCESS, SUB_RESUME_FAIL, SUB_RESUME_REQUEST, SUB_RESUME_SUCCESS,
} from "../constants/subscriptionConstants";

export const detailsSubscription = () => async (dispatch) => {
  try {
    dispatch({
      type: SUB_DETAILS_REQUEST,
    });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const { data } = await Axios.get("api/orders/subscription", {
      headers: {
        Authorization: " Bearer " + userInfo.token,
      },
    });

    dispatch({ type: SUB_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SUB_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const cancelSubscription = (pause) => async (dispatch) => {
  try {
    dispatch({
      type: SUB_CANCEL_REQUEST,
      payload: pause,
    });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const { data } = await Axios.post(
      "api/orders/cancelSubscription",
      { pause },
      {
        headers: {
          Authorization: " Bearer " + userInfo.token,
        },
      }
    ).then(function () {
      window.location.reload(false);
    });

    dispatch({ type: SUB_CANCEL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUB_CANCEL_FAIL, payload: error.message });
  }
};

export const resumeSubscription = async(dispatch) =>  {
  try {
    dispatch({
      type: SUB_RESUME_REQUEST,
    });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));


    const { data } = await Axios.get(
        "api/orders/resumeSubscription",
        {
          headers: {
            Authorization: " Bearer " + userInfo.token,
          },
        }
    ).then(function () {
      window.location.reload(false);
    });

    dispatch({ type: SUB_RESUME_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUB_RESUME_FAIL, payload: error.message });
  }
}