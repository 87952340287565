import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom/index";
import { createOrder } from "../../actions/orderActions";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { getUserInfo } from "../../actions/userActions";

export const Shipping = () => {
  const { t } = useTranslation();
  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const subscriptionInfo = useSelector((state) => state.subscription);
  const { loading, error, subscription } = subscriptionInfo;

  const [fullName, setFullName] = useState(
    !loading && subscription ? subscription.name : null
  );
  const [address, setAddress] = useState(
    !loading && subscription ? subscription.address : null
  );
  const [city, setCity] = useState(
    !loading && subscription ? subscription.city : null
  );
  const [postalCode, setPostalCode] = useState(
    !loading && subscription ? subscription.postalCode : null
  );

  useEffect(() => {
    if (!userInfo) {
      navigate("/login", { state: { from: location.pathname } });
    }

    // dispatch(detailsSubscription());
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    const shippingAddress = {
      name: fullName,
      address: address,
      city: city,
      postalCode: postalCode,
    };

    dispatch(
      createOrder(JSON.parse(localStorage.getItem("order")), shippingAddress)
    );
  };

  return (
    <>
      <div className="App">
        <Navbar />
        <div className="sectionsub">
          <form className="form" onSubmit={submitHandler}>
            <div>
              <h1>{t("shipping")}</h1>
            </div>
            <div>
              <label htmlFor="fullName">{t("shipping2")}</label>
              <input
                type="text"
                id="fullName"
                placeholder={t("shipping2")}
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              ></input>
            </div>
            <div>
              <label htmlFor="address">{t("shipping3")}</label>
              <input
                type="text"
                id="address"
                placeholder={t("shipping3")}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              ></input>
            </div>
            <div>
              <label htmlFor="city">{t("shipping4")}</label>
              <input
                type="text"
                id="city"
                placeholder={t("shipping4")}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              ></input>
            </div>
            <div>
              <label htmlFor="postalCode">{t("shipping5")}</label>
              <input
                type="text"
                id="postalCode"
                placeholder={t("shipping5")}
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                required
              ></input>
            </div>
            <div>
              <label />
              <button className="continue" type="submit">
                {t("shipping6")}
              </button>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </>
  );
};
