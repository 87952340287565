import React, { useEffect, useState } from "react";
import { TitleUnderline } from "../../components/TitleUnderline";
import { useTranslation } from "react-i18next";
import { Diagonalbox } from "../../components/Diagonalbox";

export const Steps = ({ title, subtitle, id }) => {
  const { t } = useTranslation();
  return (
    <div className={"section"}>
      <div className="flip">
        {" "}
        <Diagonalbox color="#f59a75" />
      </div>
      <div className="sectiondiv" id={id}>
        <div className="content-title">
          <h1 className="align-right">{title}</h1>
          <TitleUnderline />
        </div>

        <div className="content">
          <div className="steps-holder">
            <div className="steps-flexbox"></div>
            <p className="steps-numbers">1.</p>
            <div className="steps-flexbox">
              <p className="steps-text">{t("how1")}</p>
            </div>
          </div>

          <div className="steps-holder">
            <div className="steps-flexbox steps-alignright">
              <p className="steps-text">{t("how2")}</p>
            </div>
            <p className="steps-numbers ">2.</p>
            <div className="steps-flexbox"></div>
          </div>
          <div className="steps-holder">
            <div className="steps-flexbox"></div>
            <p className="steps-numbers">3.</p>
            <div className="steps-flexbox">
              <p className="steps-text">{t("how3")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
