import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import productListReducer from "./reducers/productReducers";
import { orderReducer } from "./reducers/orderReducer";
import {
  passwordResetReducer,
  userRegisterReducer,
  userSigninReducer,
} from "./reducers/userReducer";
import Cookie from "js-cookie";
import { subscriptionReducer } from "./reducers/subscriptionReducers";
import {historyReducer, historyReducers} from "./reducers/historyReducers";

const orderInfo = Cookie.getJSON("orderInfo") || [];
const userInfo = Cookie.getJSON("userInfo") || null;

const initialState = {
  orderInfo: { orderInfo, shipping: {}, payment: {} },
  userSignin: { userInfo },
};

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userPasswordreset: passwordResetReducer,
  userRegister: userRegisterReducer,
  productList: productListReducer,
  order: orderReducer,
  subscription: subscriptionReducer,
  paymentHistory: historyReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
