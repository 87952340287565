import CapeQueen from "../assets/product/CapeQueen.jpg";
import FloraExplosion from "../assets/product/FloraExplosion.jpg";
import FruityFred from "../assets/product/FruityFred.jpg";
import GreenGod from "../assets/product/GreenGod.jpg";
import OG from "../assets/product/OG.jpg";
import SmoothEarl from "../assets/product/SmoothEarl.jpg";
import Sweattea from "../assets/product/Sweattea.jpg";
import Zestea from "../assets/product/Zestea.jpg";

const productImage = (fileName) => {
    let image;

    let bgColor = {
        color: "white",
        backgroundColor: "DodgerBlue",
    };
    switch(fileName) {
        case "CapeQueen.jpg":
            image = CapeQueen;
            bgColor.backgroundColor = "#B8255F";
            break;
        case "FloraExplosion.jpg":
            image =  FloraExplosion;
            bgColor.backgroundColor = "#7ECC49";
            break;
        case "FruityFred.jpg":
            image =  FruityFred;
            bgColor.backgroundColor = "#884DFF";
            break;
        case "GreenGod.jpg":
            image =  GreenGod;
            bgColor.backgroundColor = "#6ACCBC";
            break;
        case "OG.jpg":
            image =  OG;
            bgColor.backgroundColor = "#158FAD";
            break;
        case "SmoothEarl.jpg":
            image =  SmoothEarl;
            bgColor.backgroundColor = "#FF8D85";
            break;
        case "Sweattea.jpg":
            image =  Sweattea;
            bgColor.backgroundColor = "#FF9933";
            break;
        case "Zestea.jpg":
            image =  Zestea;
            bgColor.backgroundColor = "#6ACCBC";
    }

    return [image, bgColor];
}

export default productImage