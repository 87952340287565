import React from "react";
import { useTranslation } from "react-i18next";
import "../translations/i18n.js";

import Whatsapp from "../assets/socialmedia/whatsapp.svg";
import Instagram from "../assets/socialmedia/instagram.svg";
import Tiktok from "../assets/socialmedia/tiktok.svg";
import Youtube from "../assets/socialmedia/youtube.svg";
import { useNavigate } from "react-router-dom";

export const Footer = ({ title, subtitle, id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const submitHandler = (path) => {
    navigate(path);
  };

  return (
    <div className={"section footerbg"}>
      <div className="row" id={id}>
        <div className="column">
          <h2> Tealivered </h2>
          {/*<p>{t("footer1")}</p>*/}
          {/*<p>{t("footer2")}</p>*/}
          {/*<p>{t("footer3")}</p>*/}
          {/*<p>{t("footer8")}</p>*/}

          <label onClick={() => submitHandler("/about")}>{t("footer1")}</label>
          <label onClick={() => submitHandler("/qualitea")}>
            {t("footer2")}
          </label>
          {/*<label onClick={() => submitHandler("/subscriptioninfo")}>*/}
          {/*  {t("footer3")}*/}
          {/*</label>*/}
        </div>

        <div className="column">
          <h2> {t("footer9")} </h2>
          <label onClick={() => submitHandler("/privacydeclaration")}>
            {t("footer4")}
          </label>
          <label onClick={() => submitHandler("/termsandconditions")}>
            {t("footer5")}
          </label>
          <label onClick={() => submitHandler("/faq")}>{t("footer6")}</label>
        </div>

        <div className="column">
          <h2> {t("footer7")} </h2>
          <a href="mailto:hoi@tealivered.nl">hoi@tealivered.nl</a>
          {/*<p> {t("whatsapp")} </p>*/}
        </div>

        <div className="column">
          <h2> Communitea </h2>
          {/*<div className="footer-icons">*/}
          {/*  <img src={Whatsapp} alt="whatsapp" width={"40px"} />*/}
          {/*</div>*/}
          <div className="footer-icons">
            <img
              src={Instagram}
              alt="instagram"
              width={"40px"}
              onClick={() => {
                window.open("https://www.instagram.com/tealivered.nl");
              }}
            />
          </div>
          {/*<div className="footer-icons">*/}
          {/*  <img src={Tiktok} alt="tiktok" width={"40px"} />*/}
          {/*</div>*/}
          {/*<div className="footer-icons">*/}
          {/*  <img src={Youtube} alt="youtube" width={"40px"} />*/}
          {/*</div>*/}
        </div>
      </div>

      <div className="footer-text">
        <p>{t("footer10")}</p>
      </div>
    </div>
  );
};
