import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { resetPassword } from "../../actions/userActions";
import MessageBox from "../../components/MessageBox";
import Warning from "../../assets/icons/Code/Warning-2.svg";
import {noWhitespace} from "../../utils";

export const Resetpassword = () => {
  const navigate = useNavigate();
  const { id, token } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [formsend, setFormsend] = useState(false);

  const userPasswordreset = useSelector((state) => state.userPasswordreset);
  const { userInfo, loading, error } = userPasswordreset;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(resetPassword(id, token, password, passwordConfirm));
    setFormsend(true);
  };

  function backToLogin() {
    navigate("/login");
  }

  useEffect(() => {
    if (error) {
      setFormsend(false);
    }
  });

  return (
    <>
      <div className="App">
        <Navbar />
        <div className="sectionsub">
          <form className="form" onSubmit={submitHandler}>
            <div>
              <h1>{t("resetpassword1")}</h1>
            </div>

            <div>
              {error ? (
                <MessageBox variant="danger">
                  <label className="notification warning">
                    <img src={Warning} alt={""} /> {t(noWhitespace(error))}
                  </label>
                </MessageBox>
              ) : null}
              {formsend ? (
                <>
                  <label>{t("resetpassword3")}</label>
                  <a onClick={backToLogin} className={"link"}>
                    {t("resetpassword5")}
                  </a>
                </>
              ) : (
                <>
                  <label>{t("resetpassword4")}</label>
                  <input
                    type="password"
                    id="password"
                    placeholder={t("resetpassword4")}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  ></input>

                  <label>{t("resetpassword7")}</label>
                  <input
                    type="password"
                    id="passwordConfirm"
                    placeholder={t("resetpassword7")}
                    required
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  ></input>

                  <button
                    className="primary"
                    onClick={submitHandler}
                    type="submit"
                  >
                    {t("resetpassword6")}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};
