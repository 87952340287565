import React, { useEffect } from "react";
import { Diagonalbox } from "../../components/Diagonalbox";
import { CircularProgress } from "@material-ui/core/index";
import { Product } from "../../components/product";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../actions/productActions";
import Carousel from "react-elastic-carousel/dist/index";
import { TitleUnderlineSingle } from "../../components/TitleUnderline";

export const Ourteas = ({ title, id }) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4,  itemsToScroll: 2  },
    { width: 1200, itemsToShow: 5 },
  ];

  return (
    <div className="section redbg">
      <Diagonalbox color="#FFFFFF" />
      <div className="sectiondiv" id={id}>
        <div className="content">
          <div className="content-title">
            <h1>{title}</h1>
            <TitleUnderlineSingle color={"#fddb82"} />
          </div>

          {loading ? (
            <CircularProgress />
          ) : error ? (
            <p>Error loading data</p>
          ) : (
            <>
              <Carousel
                className="carousel-teas"
                breakPoints={breakPoints}
                // isRTL
              >
                {products.map((product, index) => (
                  <Product
                    key={product._id}
                    product={product}
                    clickProduct={() => {return false}}
                  ></Product>
                ))}
              </Carousel>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
