import React from "react";
import { Diagonalbox } from "../../components/Diagonalbox";
import { Link } from "react-router-dom/index";
import SubscriptionsSelect from "../../components/SubscriptionsSelect";
import { TitleUnderlineRedGreen } from "../../components/TitleUnderline";
import {useTranslation} from "react-i18next";

export const Subscriptions = ({ title, subtitle, id }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={"section yellowbg"}>
        <Diagonalbox color="#ffffff" />
        <div className="sectiondiv" id={id}>
          <div className="content-title">
            <h1 className={""}>{title}</h1>
            <TitleUnderlineRedGreen />
          </div>

          <SubscriptionsSelect />

          <Link to="/order">
            <button className={"sub-order"}>{t("sub6")}</button>
          </Link>
        </div>
      </div>
    </>
  );
};
