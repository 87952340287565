import React, { Fragment, useEffect, useState } from "react";
import productImage from "./productImage";
import { useTranslation } from "react-i18next";

export const Product = ({ key, product, clickProduct, flavors }) => {
  const { t } = useTranslation();
  const [image, bgcolor] = productImage(product.image);
  flavors = flavors || [];

  return (
    <>
      <div
        className={`productBanner ${
          flavors.includes(product.productName) ? "active" : ""
        }`}
        onClick={(e) => {
          clickProduct(e, product.productName);
        }}
      >
        <p className="productFoooterText" style={bgcolor}>
          {product.name}
        </p>

        <span className="dot"></span>
        <img className="productImage" src={image} />

        <div className="productDescription">
          <h2>{t(product.productName)}</h2>

          <p className="productText">{t(product.description)}</p>
        </div>
      </div>
    </>
  );
};
