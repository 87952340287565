import React from "react";

export const Diagonalbox = ({
  color,
}) => {

  return (
	  <div className="diagonal-box" style={{backgroundColor: color}}>
		  <div className="diagonalbox-content"></div>
	  </div>
  );
};
