import React from "react";
import { TitleUnderline } from "../../components/TitleUnderline";
import { useTranslation } from "react-i18next";
import Flower from "../../assets/icons/Home/Flower2.svg";
import Heart from "../../assets/icons/General/Heart.svg";
import Mail from "../../assets/icons/Communication/Mail.svg";
import Settings from "../../assets/icons/General/Settings-3.svg";

export const About = ({ title, subtitle, id }) => {
  const { t } = useTranslation();
  return (
      <div className="sectiondiv" id={id}>
        {/*<div className="content">*/}
        <div className="content-title">
          <h1 className="align-right">{title}</h1>
          <TitleUnderline />
        </div>

        <div className="row">
          <div className="column">
            <img src={Flower} alt="flower" width={"60px"} />
            <h2>{t("biological")}</h2>
            <p>{t("biological2")}</p>
          </div>
          <div className="column">
            <img src={Heart} alt="flower" width={"60px"} />
            <h2>{t("quality1")}</h2>
            <p>{t("quality2")}</p>
          </div>
          <div className="column">
            <img src={Mail} alt="flower" width={"60px"} />
            <h2>{t("delivery")}</h2>
            <p>{t("delivery2")}</p>
          </div>
          <div className="column">
            <img src={Settings} alt="flower" width={"60px"} />
            <h2>{t("pause")}</h2>
            <p>{t("pause2")}</p>
          </div>
        </div>
      </div>
    // </div>
  );
};
