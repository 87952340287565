import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Cart from "../../assets/icons/Shopping/Cart2.svg";
import Tea from "../../assets/icons/Food/Tea2.svg";
import Layout from "../../assets/icons/Layout/Layout-4-blocks.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubscription,
  detailsSubscription,
  resumeSubscription,
} from "../../actions/subscriptionActions";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { datePredictor, reverseBoolean, subscriptionType } from "../../utils";
import { signout } from "../../actions/userActions";

export const MySubscription = ({ setPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCancelScreen, setShowCancelScreen] = useState(false);
  const [showPauseScreen, setShowPauseScreen] = useState(false);
  const [showResumeScreen, setShowResumeScreen] = useState(false);

  const subscriptionInfo = useSelector((state) => state.subscription);
  const { loading, error, subscription } = subscriptionInfo;

  useEffect(() => {
    dispatch(detailsSubscription());
  }, []);

  const submitHandler = () => {
    navigate("../order");
  };

  const customizeFlavours = () => {
    setPage(5);
  };

  const cancelSub = (pause) => {
    dispatch(cancelSubscription(pause));
  };

  const resumeSub = () => {
    navigate("../order");
    // dispatch(resumeSubscription)
  };

  if (error === "Invalid Token") {
    signout();
    navigate("../login");
  }

  return (
    <>
      <div className={"MySubscription"}>
        <h2>{t("mySubscription")}</h2>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <p>{t(error)}</p>
        ) : subscription ? (
          <Grid container spacing={3} className={"mysub-Holder"}>
            <Grid item xs={8} className={"mySub-Section"}>
              <img src={Cart} alt={""} />
              <div className={"mySub-wrap"}>
                <label className={"label"}>{t("mysub1")} </label>
                <label className={"info"}>{subscription.orderId}</label>
              </div>
            </Grid>
            <Grid item xs={4} className={"mySub-Section"}>
              <div className={"mySub-wrap"}>
                <label className={"label"}>{t("mysub3")}</label>
                <label className={"info"}>{t(subscription.status)}</label>
              </div>
            </Grid>
            <Grid item xs={8} className={"mySub-Section"}>
              <div className={"mySub-column"}>
                <img src={Tea} alt={""} />
                <div className={"mySub-wrap"}>
                  <label className={"label"}>{t("mysub4")}</label>
                  <label className={"info"}>
                    {subscriptionType(subscription.subscription)}
                  </label>
                </div>
              </div>

              <div className={"mySub-column"}>
                <img src={Layout} alt={""} />
                <div className={"mySub-wrap"}>
                  <label className={"label"}>{t("mysub5")}</label>
                  <label className={"info"}>
                    {subscription && subscription.flavors ? (
                      subscription.flavors.map((flavor, index) => (
                        <label key={index} className="table-label">
                          {flavor}
                        </label>
                      ))
                    ) : (
                      <p>No flavors available.</p>
                    )}
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} className={"mySub-Section"}>
              <div className={"mySub-wrap"}>
                <label className={"label"}>{t("mysub6")}</label>
                <label className={"info"}>
                  {subscription.status === "canceled"
                    ? "-"
                    : datePredictor(
                        subscription.createdAt,
                        subscription.subscription
                      )}
                </label>
              </div>
            </Grid>
          </Grid>
        ) : null}

        <div className={"button-holder"}>
          {subscription ? (
            error ? (
              <>
                {" "}
                <button className="edit" onClick={submitHandler}>
                  {t("mysub11")}
                </button>
              </>
            ) : (
              <>
                <button className="edit" onClick={customizeFlavours}>
                  {t("mysub7")}
                </button>

                {subscription.status === "canceled" ? (
                  <button
                    className="edit"
                    onClick={(event) => {
                      setShowCancelScreen(false);
                      setShowResumeScreen(reverseBoolean(showResumeScreen));
                    }}
                  >
                    {t("mysub17")}
                  </button>
                ) : (
                  <button
                    className="edit"
                    onClick={(event) => {
                      setShowCancelScreen(false);
                      setShowPauseScreen(reverseBoolean(showPauseScreen));
                    }}
                  >
                    {t("mysub12")}
                  </button>
                )}
                <button
                  className="warning"
                  onClick={(event) => {
                    setShowPauseScreen(false);
                    setShowCancelScreen(reverseBoolean(showCancelScreen));
                  }}
                >
                  {t("mysub8")}
                </button>
              </>
            )
          ) : null}
        </div>

        {showPauseScreen ? (
          <div className={"confirmCancel"}>
            <label>{t("mysub13")}</label>

            <button className={"primary"} onClick={() => cancelSub(true)}>
              {t("mysub14")}
            </button>
          </div>
        ) : null}

        {showCancelScreen ? (
          <div className={"confirmCancel"}>
            <label>{t("mysub9")}</label>

            <button className={"primary"} onClick={() => cancelSub(false)}>
              {t("mysub10")}
            </button>
          </div>
        ) : null}

        {showResumeScreen ? (
          <div className={"confirmCancel"}>
            <label>{t("mysub15")}</label>

            <button className={"primary"} onClick={() => resumeSub()}>
              {t("mysub16")}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};
