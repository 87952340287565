import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { SelectFlavors } from "../../components/SelectFlavors";
import {updateFlavors, updateShipping} from "../../actions/orderActions";

export const CustomizeFlavours = ({ setPage }) => {
  const dispatch = useDispatch();
  const subscriptionInfo = useSelector((state) => state.subscription);
  const { loading, error, subscription } = subscriptionInfo;
  const [flavors, setFlavors] = useState(subscription.flavors);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [dispatch]);

  const handleSetFlavors = (flavors) => {
    setFlavors(flavors);
  };

  const onBack = () => {
    setPage(1);
  };

  const onSave = async () => {
    try {
      // Dispatch the updateFlavors action
      await dispatch(updateFlavors(flavors));
      // The flavors have been successfully updated
      console.log('Flavors updated!');
    } catch (error) {
      // Handle any error that occurred during the update
      console.error('Error updating flavors:', error);
    }
  };

  return (
    <>
      <h2>{t("mySub18")} </h2>

      <SelectFlavors
        subscriptionType={subscription.subscription}
        flavors={subscription.flavors}
        handleSetFlavors={handleSetFlavors}
      />

      <div className={"button-holder"}>
        {/*{error ? null : subscription ? (*/}
        <>
          <button className="edit" onClick={onBack}>
            {t("back")}
          </button>
          <button className={`save ${flavors.length === 0 ? "disabled" : ""}`} onClick={onSave}>
            {t("save")}
          </button>{" "}
        </>
      </div>
    </>
  );
};
