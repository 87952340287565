import React from "react";

export const reverseBoolean = (boolean) => {
  if (!boolean) {
    return true;
  } else {
    return false;
  }
};

export const noWhitespace = (string) => {
  return string.replace(/\s/g, "");
};

export const subscriptionType = (number) => {
  switch (number) {
    case 1:
      return "Rustaaag";
    case 2:
      return "Ja hoor, ja";
    case 3:
      return "Gezellig";
    case 4:
      return "Thee = leven";
    case 5:
      return "Family fun";
    default:
      return "No sub active";
  }
};

export const dateFormatter = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const myDate = date.split("T")[0];
  const y = myDate.split("-");
  const convertedDate = new Date(y[0], y[1] - 1, y[2]);
  return (
    monthNames[convertedDate.getMonth()] + " " + convertedDate.getFullYear()
  );
};

export const datePredictor = (date, sub) => {
  const monthNames = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mri",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "October",
    "November",
    "December",
  ];

  const myDate = date.split("T")[0];
  const y = myDate.split("-");
  let convertedDate = new Date();




  if (convertedDate.getDate() >= y[2]) {
    convertedDate.setMonth(convertedDate.getMonth() + (sub === 1 ? 2 : 1 ));
  }

  if (Number(y[2]) < 15) {
    convertedDate.setDate(15);
  } else {
    convertedDate.setDate(30);
  }

  return (
    convertedDate.getDate() +
    " " +
    monthNames[convertedDate.getMonth()] +
    " " +
    convertedDate.getFullYear()
  );
};


