import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {forgotPassword} from "../../actions/userActions";

export const Forgotpassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [formsend, setFormsend] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(forgotPassword(email));
    setFormsend(true);
  };

  function backToLogin(){
    navigate("/login")
  }

  return (
    <>
      <div className="App">
        <Navbar />
        <div className="sectionsub">
          <form className="form" onSubmit={submitHandler}>
            <div>
              <h1>{t("forgotpassword1")}</h1>
            </div>

            <div>
              {formsend ? (
                <>
                  <label>{t("forgotpassword3")}</label>
                  <a onClick={backToLogin} className={"link"}>
                    {" "}
                    {t("forgotpassword5")}
                  </a>
                </>
              ) : (
                <>
                  <label>{t("forgotpassword2")}</label>
                  <input
                    type="email"
                    id="email"
                    placeholder={t("forgotpassword4")}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>

                  <button
                    className="primary"
                    onClick={submitHandler}
                    type="submit"
                  >
                    {t("forgotpassword6")}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};
