import React, {useEffect} from "react";
import { Footer } from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";

export const SubscriptionsInfo = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    });

    return (
        <>
            <div className="App">
                <Navbar />

                <div className="sectionsub">
                    <div className={"section-paragraph"}>
                        <h1>{t("faq1")} </h1>
                        <h2>{t("tea")}</h2>
                        <label>{t("faq2")}</label>
                        <p>{t("faq3")}</p>
                        <p>{t("faq4")}</p>
                        <label>{t("faq5")}</label>
                        <p>{t("faq6")}</p>
                        <p>{t("faq7")}</p>
                    </div>

                    <div className={"section-paragraph"}>
                        <h2>{t("faq8")}</h2>
                        <label>{t("faq9")}</label>
                        <p>{t("faq10")}</p>
                        <label>{t("faq11")}</label>
                        <p>{t("faq12")}</p>
                        <label>{t("faq13")}</label>
                        <p>{t("faq14")}</p>
                        <label>{t("faq15")}</label>
                        <p>{t("faq16")}</p>
                        <label>{t("faq17")}</label>
                        <p>{t("faq18")}</p>
                        <label>{t("faq19")}</label>
                        <p>{t("faq20")}</p>
                        <label>{t("faq21")}</label>
                        <p>{t("faq22")}</p>
                        <label>{t("faq23")}</label>
                        <p>{t("faq24")}</p>
                        <p>{t("faq25")}</p>
                        <p>{t("faq26")}</p>
                        <label>{t("faq27")}</label>
                        <p>{t("faq28")}</p>
                    </div>

                    <div className={"section-paragraph"}>
                        <h2>{t("faq29")}</h2>
                        <label>{t("faq30")}</label>
                        <p>{t("faq31")}</p>
                        <label>{t("faq32")}</label>
                        <p>{t("faq33")}</p>
                        <p>{t("faq34")}</p>
                        <p>{t("faq35")}</p>
                        <label>{t("faq36")}</label>
                        <p>{t("faq37")}</p>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};
