import React, {useEffect} from "react";
import Navbar from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  });

  return (
    <>
      <div className="App">
        <Navbar />

        <div className="sectionsub">
          <div className={"section-paragraph"}>
            <h1>{t("tos")} </h1>
            <label>{t("tos1")}</label>
            <p>{t("tos2")}</p>
              <label>{t("tos3")}</label>
              <p>{t("tos4")}</p>
              <label>{t("tos5")}</label>
              <p>{t("tos6")}</p>
              <label>{t("tos7")}</label>
              <p>{t("tos8")}</p>
              <label>{t("tos9")}</label>
              <p>{t("tos10")}</p>
              <label>{t("tos11")}</label>
              <p>{t("tos12")}</p>
              <label>{t("tos13")}</label>
              <p>{t("tos14")}</p>
              <label>{t("tos15")}</label>
              <p>{t("tos16")}</p>
              <label>{t("tos17")}</label>
              <p>{t("tos18")}</p>
              <label>{t("tos19")}</label>
              <p>{t("tos20")}</p>
              <label>{t("tos21")}</label>
              <p>{t("tos22")}</p>
              <label>{t("tos23")}</label>
              <p>{t("tos24")}</p>
              <label>{t("tos25")}</label>
              <p>{t("tos26")}</p>
              <label>{t("tos27")}</label>
              <p>{t("tos28")}</p>
            <p></p>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
