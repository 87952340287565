export const SUB_DETAILS_REQUEST = "SUB_DETAILS_REQUEST";
export const SUB_DETAILS_SUCCESS = "SUB_DETAILS_SUCCESS";
export const SUB_DETAILS_FAIL = "SUB_DETAILS_FAIL";

export const SUB_CANCEL_REQUEST = "SUB_CANCEL_REQUEST";
export const SUB_CANCEL_SUCCESS = "SUB_CANCEL_SUCCESS";
export const SUB_CANCEL_FAIL = "SUB_CANCEL_FAIL";

export const SUB_RESUME_REQUEST = "SUB_RESUME_REQUEST";
export const SUB_RESUME_SUCCESS = "SUB_RESUME_SUCCESS";
export const SUB_RESUME_FAIL = "SUB_RESUME_FAIL";