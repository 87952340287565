export const TRANSLATIONS_NL = {
  signout: "Uitloggen",
  userprofile: "Accountoverzicht",
  tea: "Thee",
  hwork: "Hoe werkt het?",
  about: "Over",
  ourtea: "Onze Thee",
  subs: "Abonnementen",
  edit: "Wijzigen",
  back: "Terug",
  save: "Opslaan",
  paid: "Betaald",
  expired: "Verlopen",
  failed: "Mislukt",
  canceled: "Gepauzeerd",
  passworddonotmatch: "Wachtwoorden komen niet overeen.",
  passwordincorrect: "Wachtwoord moet minimaal 8 karakters lang zijn.",
  jwtexpired: "Deze link is niet meer geldig, vraag een nieuwe aan.",
  myAddress: "Mijn adres",
  mySubscription: "Mijn abonnement",
  useralreadyexist: "Dit email adres is al in gebruik.",
  myAccount: "Mijn account",

  price1: "€8,95 p/m",
  price2: "€16,95 p/m",
  price3: "€20,95 p/m",
  price4: "€23,95 p/m",
  price5: "€25,95 p/m",

  biological: "Biologisch",
  biological2: "Zo geniet je van elke pure kop thee.",
  quality1: "Kwaliteit",
  quality2: "Ervaar thee zoals het echt is.",
  delivery: "Brievenbus",
  delivery2:
      "Zo vergeet je nooit meer thee te kopen en heb je altijd thee in huis.",
  pause: "Pauzeren",
  pause2: "Controle over je abonnement, hij is net zo flexibel als jij.",
  how1: "Kies het abonnement dat bij jou past en de lekkerste theesmaken",
  how2: "Maak een account en vul je verzendgegevens in ",
  how3: "Maandelijks heerlijke thee Tealivered bij jou thuis",
  sub1a: "Rustaaag",
  sub1b: "Genoeg thee voor 1 kop per dag voor twee maanden. Kies maximaal twee theesmaken.",
  sub2a: "Ja hoor, ja",
  sub2b: "Genoeg thee voor 2 koppen thee per dag voor één maand. Kies maximaal twee theesmaken.",
  sub3a: "Gezellig",
  sub3b: "Genoeg thee voor 3 koppen thee per dag voor één maand. Kies maximaal drie theesmaken.",
  sub4a: "Thee = leven",
  sub4b: "Genoeg thee voor 4 koppen thee per dag voor één maand. Kies maximaal vier theesmaken.",
  sub5a: "Family fun",
  sub5b: "Genoeg thee voor 5 koppen thee per dag voor één maand. Kies maximaal vier theesmaken. ",
  sub6: "Abonnement Kiezen",
  whatsapp: "Whatsapp ons!",
  footer1: "Over Ons",
  footer2: "Qualitea",
  footer3: "Abonnementen",
  footer4: "Privacy verklaring",
  footer5: "Algemene voorwaarden",
  footer6: "Veelgestelde vragen",
  footer7: "Contact",
  footer8: "Log in",
  footer9: "Info",
  footer10:
      "©2023 Tealivered | Tealivered.nl | De Brink 112, 9723 AM Groningen | KVK: 83552774 | BTW: NL862914590B01",

  accpage1: "Abonnement",
  accpage2: "Adresgegevens",
  accpage3: "Order historie",
  accpage4: "Admin",
  accpage5: "Uitloggen",

  adminview1: "Naam",
  adminview2: "Abotype",
  adminview3: "Smaken",
  adminview4: "Klant sinds",
  adminview5: "Laatste betaling",
  adminview6: "Adres",

  history1: "Abonnement",
  history2: "Smaken",
  history3: "Datum",
  history4: "Betaald",
  history5: "Factuur",

  mysubnonactive: "Het lijkt erop dat je nog geen abonnement bij ons hebt.",
  mysub1: "Ordernummer: ",
  mysub2: "In zakjes: ",
  mysub3: "Status: ",
  mysub4: "Abonnementstype: ",
  mysub5: "Smaken: ",
  mysub6: "Volgende zending: ",
  mysub7: "Smaken wijzigen",
  mysub8: "Abonnement annuleren",
  mysub9: "Weet je zeker dat je je abonnement wilt opzeggen?",
  mysub10: "Ja, zeg mijn abonnement op. Tot snel weer!",
  mysub11: "Start je abonnement",
  mysub12: "Pauzeer je abonnement",
  mysub13: "Weet je zeker dat je je abonnement wilt pauzeren?",
  mysub14: "Ja, pauzeer mijn abonnement. Tot snel weer!",
  mysub15: "Wil je je abonnement weer hervatten?",
  mysub16: "Ja, activeer mijn account",
  mysub17: "Hervat abonnement",
  mySub18: "Smaken wijzigen",


  myaddress1: "Naam",
  myaddress2: "Adres",
  myaddress3: "Stad",
  myaddress4: "Postcode",

  register1: "Account aanmaken",
  register2: "Registreren",
  register3: "Heb je al een account? ",
  register4: "Log in",

  register5: "Naam",
  register6: "Emailadress",
  register7: "Wachtwoord",
  register8: "Bevestig wachtwoord",

  login1: "Nieuwe klant?",
  login2: "Ga naar registreren",
  login3: "Log in",
  login4: "Inloggen",
  login5: "Wachtwoord vergeten?",
  login6: "E-mailadres en/of wachtwoord is niet geldig.",

  forgotpassword1: "Wachtwoord vergeten?",
  forgotpassword2:
      "Weet je het wachtwoord niet meer?  sturen we binnen enkele minuten een e-mail waarmee een nieuw wachtwoord kan worden aangemaakt.",
  forgotpassword3:
      "Bedankt, mits je e-mailadres bij ons bekend is ontvang je een e-mail met daarin een persoonlijke link. Via de link kun je een nieuw wachtwoord opgeven.",
  forgotpassword4: "Emailadress",
  forgotpassword5: "Terug naar inloggen",
  forgotpassword6: "Verzenden",

  resetpassword1: "Herstel wachtwoord",
  resetpassword2: "Voer hier je nieuwe wachtwoord in.",
  resetpassword3: "Je wachtwoord is opnieuw ingesteld.",
  resetpassword4: "Wachtwoord",
  resetpassword5: "Terug naar inloggen",
  resetpassword6: "Verzenden",
  resetpassword7: "Bevestig wachtwoord",

  completedorder: "Bestelling",
  completedorder2:
      "Bedankt voor je bestelling! We gaan aan de slag om jouw thee abonnement bij jou in de brievenbus te krijgen. Een overzicht van je bestelling is te vinden in je mailbox en ook onder je profielpagina.",

  order1: "Abonnement kiezen",
  order2: "Kies hoeveel thee je wil ontvangen.",
  order3: "Kies welke thee je wilt ontvangen.",
  order4: "Wil je je thee in zakjes of los aangeleverd?",
  order5: "Doorgaan naar verzending",
  orderActive: "Je abbonement is al actief, annuleer eerst je huidige abbonement voordat je een nieuw pakket kan starten.",

  shipping: "Verzendingsadres",
  shipping2: "Voor-en achternaam",
  shipping3: "Address",
  shipping4: "Stad",
  shipping5: "Postcode",
  shipping6: "Bestelling plaatsen",

  notfound: "Deze pagina is niet beschikbaar",

  zesttea: "Groene thee met citroen",
  zestteadesc: "Een toegankelijke groene thee met frisse citroen smaak.",
  fruiteafred: "Bosvruchten thee",
  fruiteafreddesc:
      "Een mix van zwarte thee en bosvruchten voor die licht zoet-fruitige smaak.",
  floraexplosion: "Rooibos met bloemen",
  floraexplosiondesc: "Soepele kruidenmix van rooibos en bloemen.",
  sweattea: "Mix van gember, rode peper en munt",
  sweatteadesc:
      "Kruidenmix die je scherp en warm houdt. Een licht-kruidige thee met pit.",
  capequeen: "Rooibos",
  capequeendesc: "Soepele cafeïnevrije kruidenmix.",
  greengod: "Groene thee",
  greengoddesc: "Een soepel en zachte groene thee zonder bittere nasmaak.",
  og: "Zwarte thee",
  ogdesc: "Terug naar de basis met deze zwarte indiaase assam thee.",
  smoothearl: "Earl grey",
  smoothearldesc: "De vertrouwde smaak van thee.",

  faq1: "Veelgestelde vragen",
  faq2: "→ Wat voor thee verkopen jullie?",
  faq3: "Wij verkopen 8 verschillende biologische theeën en kruiden mixen. Zo is er voor iedereen iets te kiezen zonder keuzestress, wel zo fijn.",
  faq4: "Ook hebben we een wisselende theesmaak ‘Dé Thee’. Deze thee of kruidenmix is voor een korte periode in het assortiment. Je kunt deze aan je abonnement toevoegen door tussentijds van theesmaak te veranderen.  Dit doe je gemakkelijk vanuit jouw account.",
  faq5: "→ Waar komt jullie thee vandaan?",
  faq6: "Wij kopen de thee in bij onze leverancier 'De Theefabriek'.",
  faq7: "De zwarte thee is een lekkere stevige thee dat uit de Assam regio in India komt. De groene thee komt uit Japan, de rooibosthee komt uit Zuid-Afrika, de sweattea kruidenmix is een homemade indiaas-egyptische blend, de Smooth Earl komt heeft theePlus als oorsprong),",
  faq8: "Abonnement",
  faq9: "→ Hebben jullie alleen abonnementen?",
  faq10:
      "Nee, je kunt eerst ons ‘Tease’ proefpakket proberen en daarna beslissen welk abonnement bij jou past.",
  faq11: "→ Hoe wissel ik van smaak? ",
  faq12:
      "Vanuit je accountpagina kun je de smaken voor de komende maand selecteren. Jouw referentie datum bepaalt per wanneer je nieuwe smaken in je theepakket zitten. Referentie datum tussen 1e en 14e van de maand: wijzig je theesmaken of abonnementsvorm voor de 15e van de maand om in de maand erop je nieuwe theepakket te ontvangen.Referentie datum tussen de 15e en 31e van de maand: wijzig je theesmaken of abonnement voor het einde van de maand om voor de maand erop je nieuwe theepakket te ontvangen. Als je niets wijzigt krijg je dezelfde smaken als bij je eerste bestelling.",
  faq13: "→ Wat is een referentie datum?",
  faq14:
      "Referentie datum is de datum waarop jij je allereerste bestelling voor een abonnement hebt geplaatst. Deze datum bepaalt je daarop volgende leveringsmomenten. Ook is deze datum van belang voor het doorvoeren van wijzigingen in je theepakket. Lees meer hierover onder ‘Hoe wissel ik van smaak?’.",
  faq15: "→ Hoe stop ik mijn abonnement?",
  faq16:
      "Als je inlogt kun je je abonnement pauzeren of stoppen. Als je gaat stoppen willen we je vragen ook een mail te sturen naar hoi@tealivered.nl waarin je aangeeft dat je je abonnement wilt opzeggen. Bij opzegging wordt je eerstvolgende levering gestopt.",
  faq17: "→ Ik drink niet zoveel thee. Welk abonnement past het beste bij mij?",
  faq18:
      "Het Rustaaag abonnement past het beste bij jouw theebehoefte. Met dit abonnement krijg je elke 2 maanden thee thuisgestuurd. Drink je elke dag wel een kopje thee? Kies dan voor het Ja hoor, ja abonnement. Dan krijg je voldoende voor elke dag twee koppen thee.",
  faq19:
      "→ Ik heb vaak vrienden/familie over de vloer. Heb ik met Tealivered genoeg thee in huis?",
  faq20:
      "Voor grote theeliefhebbers past een thee = leven abonnement erg goed als je met meer dan 1 persoon ervan wilt genieten. Zijn er meer theeliefhebbers in huis? Een Family Fun abonnement past dan beter.",
  faq21: "→ Kan ik ook wisselen van abonnement?",
  faq22:
      "Ja! Tot 14 dagen voor de volgende levering kun je je abonnementsvorm wijzigen. Dit kun je doen door een wijziging door te geven via je account. Log in en kies de menuoptie abonnement.",
  faq23: "→ Hoe werkt het maandelijkse abonnement/verzending?",
  faq24:
      "Vanaf je tweede theepakket krijg je elke maand een theepakket thuisgestuurd. Wanneer je maandelijkse theepakket komt, is afhankelijk van je allereerste bestelling. Heb jij je eerste bestelling gedaan voor de 15e van de maand? Dan ontvang jij jouw tweede, derde, vierde (maandelijkse) theepakket daarna altijd aan het begin van de maand. We streven ernaar de eerste werkdag van de maand de verzending bij je thuis te hebben.     Heb jij je eerste bestelling gedaan op of na de 15e van de maand? Dan ontvang jij jouw tweede, derde, vierde (maandelijkse) theepakket daarna altijd rond het midden van de maand. We streven ernaar je verzending rond elke 15e/eerste werkdag in het midden van de maand bij je thuis te hebben.",
  faq25:
      "Op de bevestiging van je allereerste pakket staat jouw referentie datum voor je vervolgleveringen.",
  faq26:
      "Alleen bij een Rustaaag abonnement krijg je om de maand een theepakket thuisgestuurd. Deze komt ook aan het begin van de maand of rond het midden van de maand. Afhankelijk van je allereerste bestelling.",
  faq27: "→ Hoe lang loopt mijn abonnement?",
  faq28:
      "Tot je hem opzegt door je account op te zeggen en te mailen naar hoi@tealivered.nl",
  faq29: "Verzending",
  faq30: "→ Wanneer kan ik mijn thee verwachten?",
  faq31: "Je eerste theepakket ontvang je binnen enkele werkdagen.",
  faq32: "→ Hoe werkt het maandelijkse abonnement/verzending?",
  faq33:
      "Vanaf je tweede theepakket krijg je elke maand een theepakket thuisgestuurd. Wanneer je maandelijkse theepakket komt, is afhankelijk van je allereerste bestelling. Heb jij je eerste bestelling gedaan voor de 15e van de maand? Dan ontvang jij jouw tweede, derde, vierde (maandelijkse) theepakket daarna altijd aan het begin van de maand. We streven ernaar de eerste werkdag van de maand de verzending bij je thuis te hebben. Heb jij je eerste bestelling gedaan op of na de 15e van de maand? Dan ontvang jij jouw tweede, derde, vierde (maandelijkse) theepakket daarna altijd rond het midden van de maand. We streven ernaar je verzending rond elke 15e/eerste werkdag in het midden van de maand bij je thuis te hebben.",
  faq34:
      "Op de bevestiging van je allereerste pakket zie staat jouw referentie datum voor je vervolgleveringen.",
  faq35: "→ Past het ook door mijn brievenbus?",
  faq36:
      "De minimale afmeting van een brievenbus volgens PostNL is minimaal 26,5 cm lang en 3,2 cm hoog. Het grootste brievenbuspakket dat we uitsturen is van A4 formaat (35 x 25 x 2,5 cm), het hoogste pakket dat we uitsturen is van A5 formaat en heeft een hoogte van 3,0 cm. Een Tealivered pakketje past dan ook door jouw brievenbus.",

  tos: "Algemene voorwaarden",

  tos1: "Artikel 1 - Definities ",

  tos2:
      "In deze voorwaarden wordt verstaan onder: \n\n" +
      "Bedenktijd: de termijn waarbinnen de consument gebruik kan maken van zijn herroepingsrecht; \n\n" +
      "Consument/klant/besteller: de natuurlijke persoon die niet handelt voor doeleinden die verband houden met zijn handels-, bedrijfs-, ambachts- of beroepsactiviteit; \n\n" +
      "Dag: kalenderdag; \n\n" +
      "Digitale inhoud: gegevens die in digitale vorm geproduceerd en geleverd worden; \n\n" +
      "Duurovereenkomst: een overeenkomst die strekt tot de regelmatige levering van zaken, diensten en/of digitale inhoud gedurende een bepaalde periode; \n\n" +
      "Duurzame gegevensdrager: elk hulpmiddel - waaronder ook begrepen e-mail - dat de consument of ondernemer in staat stelt om informatie die aan hem persoonlijk is gericht, op te slaan op een manier die toekomstige raadpleging of gebruik gedurende een periode die is afgestemd op het doel waarvoor de informatie is bestemd, en die ongewijzigde reproductie van de opgeslagen informatie mogelijk maakt; \n\n" +
      "Herroepingsrecht: de mogelijkheid van de consument om binnen de bedenktijd af te zien van de overeenkomst op afstand; \n\n" +
      "Ondernemer: de natuurlijke of rechtspersoon die deelnemer is aan Tealivered  en producten, (toegang tot) digitale inhoud en/of diensten op afstand aan consumenten aanbiedt; \n\n" +
      "Overeenkomst op afstand: een overeenkomst die tussen de ondernemer en de consument wordt gesloten in het kader van een georganiseerd systeem voor verkoop op afstand van producten, digitale inhoud en/of diensten, waarbij tot en met het sluiten van de overeenkomst uitsluitend of mede gebruik gemaakt wordt van één of meer technieken voor communicatie op afstand; \n\n" +
      "Modelformulier voor herroeping: het in Bijlage I van deze voorwaarden opgenomen Europese modelformulier voor herroeping; \n\n" +
      "Techniek voor communicatie op afstand: middel dat kan worden gebruikt voor het sluiten van een overeenkomst, zonder dat consument en ondernemer gelijktijdig in dezelfde ruimte hoeven te zijn samengekomen \n\n" +
      "Referentie datum: datum waarop je eerste bestelling hebt geplaatst. Deze wordt gebruikt om de vervolg leveringen te bepalen. ",

  tos3: "Artikel 2 - Identiteit van de ondernemer",
  tos4:
      "Ondernemers: Bas Jetten, Cornelis Drewes bij de Leij en Zenash Naomi Hannah Pouwels. \n" +
      "Tealivered. \n" +
      "Vestigingsadres: De Brink 112, 9723 AM te Groningen \n" +
      "E-mailadres: hoi@tealivered.nl  \n" +
      "KVK nummer: 83552774 \n" +
      "BTW-nummer: NL862914590B01 \n" +
      "Indien de activiteit van de ondernemer is onderworpen aan een relevant vergunningstelsel: de gegevens over de toezichthoudende autoriteit. ",

  tos5: "Artikel 3 - Toepasselijkheid ",
  tos6:
      "1. Deze algemene voorwaarden zijn van toepassing op elk aanbod van de ondernemer en op elke tot stand gekomen overeenkomst op afstand tussen ondernemer en consument. \n" +
      "2. Voordat de overeenkomst op afstand wordt gesloten, wordt de tekst van deze algemene voorwaarden aan de consument beschikbaar gesteld. Indien dit redelijkerwijs niet mogelijk is, zal de ondernemer voordat de overeenkomst op afstand wordt gesloten, aangeven op welke wijze de algemene voorwaarden bij de ondernemer zijn in te zien en dat zij op verzoek van de consument zo spoedig mogelijk kosteloos worden toegezonden. \n" +
      "3. Indien de overeenkomst op afstand elektronisch wordt gesloten, kan in afwijking van het vorige lid en voordat de overeenkomst op afstand wordt gesloten, de tekst van deze algemene voorwaarden langs elektronische weg aan de consument ter beschikking worden gesteld op zodanige wijze dat deze door de consument op een eenvoudige manier kan worden opgeslagen op een duurzame gegevensdrager. Indien dit redelijkerwijs niet mogelijk is, zal voordat de overeenkomst op afstand wordt gesloten, worden aangegeven waar van de algemene voorwaarden langs elektronische weg kan worden kennisgenomen en dat zij op verzoek van de consument langs elektronische weg of op andere wijze kosteloos zullen worden toegezonden. \n" +
      "4. Voor het geval dat naast deze algemene voorwaarden tevens specifieke product- of dienstenvoorwaarden van toepassing zijn, is het tweede en derde lid van overeenkomstige toepassing en kan de consument zich in geval van tegenstrijdige voorwaarden steeds beroepen op de toepasselijke bepaling die voor hem het meest gunstig is \n" +
      "Deze voorwaarden zijn van toepassing op alle offertes, bestellingen en overeenkomsten tussen de klant en Tealivered V.O.F. ''Tealivered'' \n" +
      "Deze voorwaarden worden bij eerste bestelling eenmalig geaccepteerd  en gelden voor verdere overeenkomsten in de toekomst. \n" +
      "Overeenkomst komt tot stand door het correct invullen van de bestel- en persoonsgegevens bij plaatsen van een bestelling door de besteller. \n",

  tos7: "Artikel 4 - Het aanbod en betaling",
  tos8:
      "1. Indien een aanbod een beperkte geldigheidsduur heeft of onder voorwaarden geschiedt, wordt dit nadrukkelijk in het aanbod vermeld. \n" +
      "2. Het aanbod bevat een volledige en nauwkeurige omschrijving van de aangeboden producten, digitale inhoud en/of diensten. De beschrijving is voldoende gedetailleerd om een goede beoordeling van het aanbod door de consument mogelijk te maken. Als de ondernemer gebruik maakt van afbeeldingen, zijn deze een waarheidsgetrouwe weergave van de aangeboden producten, diensten en/of digitale inhoud. Kennelijke vergissingen of kennelijke fouten in het aanbod binden de ondernemer niet. \n" +
      "3. Elk aanbod bevat zodanige informatie, dat voor de consument duidelijk is wat de rechten en verplichtingen zijn, die aan de aanvaarding van het aanbod zijn verbonden. \n" +
      "Prijzen \n" +
      "3.1 Tealivered behoudt zich het recht voor om de prijzen van de door haar in abonnement servicevorm aangeboden producten tijdens de looptijd van de service te wijzigen, waarbij Tealivered uiterlijk 14 dagen van tevoren zal informeren en de klant de mogelijkheid heeft om de service binnen 10 dagen op te zeggen zonder dat Besteller enige vergoeding verschuldigd. \n" +
      "3.3 Acties en aanbiedingen gelden zolang de voorraad strekt. \n" +
      "3.4 De persoonlijke Tealivered kortingscode is bedoeld om nieuwe betalende klanten te werven uit eigen directe kring (via mond tot mond, social media). De Tealivered kortingscodes mogen niet gebruikt worden voor commerciële doeleinden en/of andere doeleinden dan waarvoor ze zijn uitgegeven. Daarnaast is het in geen geval toegestaan om de merknaam Tealivered en alle mogelijke combinaties van verkeerde spelling te gebruiken binnen url combinaties en daarmee verkeer aan te trekken dat ingezet wordt om de kortingscode te verspreiden. \n" +
      "3.5 Iedere (poging tot) fraude of andere niet-toegestane handeling wordt geregistreerd en leidt ertoe dat het gebruik van kortingscodes wordt ontzegd. Tealivered behoudt zich het recht om de verkregen korting en het opgebouwde tegoed met terugwerkende kracht te verrekenen. \n" +
      "3.6. De in het aanbod van producten of diensten genoemde prijzen zijn inclusief btw. \n" +
      "3.7. Prijsverhogingen vanaf 3 maanden na de totstandkoming van de overeenkomst zijn alleen toegestaan indien de ondernemer dit bedongen heeft en: \n" +
      "a. deze het gevolg zijn van wettelijke regelingen of bepalingen; of \n" +
      "b. de consument de bevoegdheid heeft de overeenkomst op te zeggen met ingang van de dag waarop de prijsverhoging ingaat.  \n" +
      "Betalen \n" +
      "4.1 Voor de betaling kan Besteller uitsluitend gebruik maken van de door Tealivered aangeboden betalingsmogelijkheden. \n" +
      "4.2 Met het uitvoeren van de eerste betaling via iDeal op de Tealivered, geeft Besteller Tealivered toestemming om ook toekomstige betalingen van het betreffende rekeningnummer te innen. \n" +
      "4.3 Betaling vindt plaats uiterlijk binnen 14 dagen nadat een koopovereenkomst tot stand is gekomen. Periodieke betaling vindt plaats voorafgaand aan elke levering. \n" +
      "4.4 Bij niet tijdige betaling, bijvoorbeeld door stornering van de afgeschreven bedragen of wegens ontoereikend saldo op de betreffende rekening, kan een bedrag van EUR 2,50 aan kosten in rekening gebracht worden. \n" +
      "4.5 Indien de betaling na de eerste herinnering niet binnen de daarin gestelde termijn plaatsvindt, is Tealivered gerechtigd het abonnement eenzijdig per direct te beëindigen, onverminderd het recht op schadevergoeding en het recht om achterstallige betalingen te innen en de bijbehorende incassokosten op Besteller te verhalen. \n" +
      "4.6 Facturen en betalingsherinneringen worden door Tealivered uitsluitend elektronisch aangeboden. \n" +
      "4.7 Het door Tealivered te incasseren bedrag is gelijk aan het bedrag van de door Besteller geplaatste bestelling. \n" +
      "4.8 Als door een wijziging tijdens de looptijd van het abonnement, een verhoging dan wel een verlaging het te incasseren bedrag wijzigt behoud Tealivered het recht van de automatische incasso, totdat het abonnement wordt stopgezet. \n" +
      "​4.9​. De consument heeft de plicht om onjuistheden in verstrekte of vermelde betaalgegevens onverwijld aan de ondernemer te melden.  \n" +
      "5. Contract \n" +
      "\n" +
      "De besteller kan ten alle tijden zijn abonnement wijzigen of opzeggen met Tealivered rekening houdend met het opzegtermijn van 14 dagen voor de volgende levering. Mocht de wijziging in die 14 dagen vallen dan wordt de wijziging bij de eerstvolgende levering daarop verwerkt. ",
  tos9: "Artikel 5  - Bezorging en Levering ",
  tos10:
      "Bezorging en levering \n" +
      "5.1. Tealivered  bezorgt via postpakket service door heel Nederland.  \n" +
      "5. 1.1 Tealivered streeft naar bezorging op elke 1e van de maand voor een ieder die een referentie datum heeft tussen 1e en 14e van de maand . Indien de 1e van de maand op een  maandag of zondag valt zal bezorging op de dinsdag daarop geschieden. Tealivered streeft naar bezorging op elke 15e van de maand voor een ieder die een referentie datum heeft tussen de 15e en 31e van de maand. Indien de 15e van de maand op een maandag of zondag valt zal bezorging op de dinsdag daarop geschieden.  \n" +
      "5. 1.2 Tealivered bezorgt op het adres dat u in uw account op onze website als bezorgadres heeft opgegeven. Wilt u het afleveradres wijzigen en deze een gereedstaande levering kruisen dan zal deze uiterlijk voor de daarop volgende levering zijn verwerkt.  \n" +
      "5. 1.3Als de brievenbus overdracht van de producten niet mogelijk is, kan aan de overeenkomst worden voldaan door de producten aan te bieden bij buren of bij het dichtstbijzijnde afhaalpunt \n" +
      " 5. 1.4 Mochten de producten op het moment van de persoonlijke overdracht nog niet betaald zijn, behoudt Tealivered het eigendom tot het aankoopbedrag volledig betaald is. \n" +
      "5. 1.5 Tealivered garandeert slechts dat de door haar geleverde producten geschikt zijn voor het doel waarvoor zij op grond van de door Tealivered  verstrekte informatie bestemd zijn en garandeert dat de producten voldoen aan de eventuele wettelijke verplichtingen en andere overheidsvoorschriften die gelden voor dat gebruiksdoel. \n" +
      " 5.2 Tealivered biedt een proefpakket. Per woonadres kan er één proefpakket besteld worden. Dit proefpakket wordt eenmalig toegestuurd.  \n" +
      " 5.3 Tealivered een GiftTea aan. Waarmee je voor enkele maanden thee tegoed koopt voor iemand anders. \n" +
      "5.4 Met inachtneming van hetgeen hierover in artikel 4 van deze algemene voorwaarden is vermeld, zal de ondernemer geaccepteerde bestellingen met bekwame spoed doch uiterlijk binnen 30 dagen uitvoeren, tenzij een andere leveringstermijn is overeengekomen. Indien de bezorging vertraging ondervindt, of indien een bestelling niet dan wel slechts gedeeltelijk kan worden uitgevoerd, ontvangt de consument hiervan uiterlijk 30 dagen nadat hij de bestelling geplaatst heeft bericht. De consument heeft in dat geval het recht om de overeenkomst zonder kosten te ontbinden en recht op restitutie van het aankoopbedrag.  \n" +
      "5.5 Gegevensbescherming \n" +
      "Om te zien welke gegevens Tealivered bijhoudt en bewaard vindt u meer informatie in de privacyverklaring.",
  tos11: "Artikel 6 - Overeenkomst ",
  tos12:
      "​De overeenkomst komt, onder voorbehoud van het bepaalde in artikel 4 en 5 , tot stand op het moment van aanvaarding door de consument van het aanbod en het voldoen aan de daarbij gestelde voorwaarden. \n" +
      "6.1. De ondernemer zal uiterlijk bij levering van het product, de dienst of digitale inhoud aan de consument de volgende informatie, elektronisch meesturen: a. het adres van de vestiging; b. het e-mailadres waar de consument met klachten terecht kan; c. de voorwaarden waaronder en de wijze waarop de consument van het herroepingsrecht gebruik kan maken; d. de prijs met inbegrip van alle belastingen van het product, dienst of digitale inhoud; voor zover van toepassing de kosten van aflevering; en de wijze van betaling, aflevering of uitvoering van de overeenkomst op afstand; e. de vereisten voor opzegging van de overeenkomst indien de overeenkomst een duur heeft van meer dan één jaar of van onbepaalde duur is; f. indien de consument een herroepingsrecht heeft, het modelformulier voor herroeping.  ",
  tos13: "Artikel 7 - Verplichtingen van de consument tijdens de bedenktijd ",
  tos14:
      "7.1. Tijdens de bedenktijd zal de consument zorgvuldig omgaan met het product en de verpakking. Hij zal het product slechts uitpakken of gebruiken in de mate die nodig is om de aard, de kenmerken en de werking van het product vast te stellen. Het uitgangspunt hierbij is dat de consument het product geproefd heeft, maar het overgrote deel van het product ongebruikt aanwezig is in de originele verpakking.  \n" +
      "7.2. De consument is niet aansprakelijk voor waardevermindering van het product als de ondernemer hem niet voor of bij het sluiten van de overeenkomst alle wettelijk verplichte informatie over het herroepingsrecht heeft verstrekt. ",
  tos15:
      "Artikel 8 - Uitoefening van het herroepingsrecht door de consument en kosten daarvan ",
  tos16:
      "8.1. Als de consument gebruik maakt van zijn herroepingsrecht, meldt hij dit binnen de bedenktermijn door middel van het modelformulier voor herroeping deze is te vinden op de website.  \n" +
      "8.2. Zo snel mogelijk, maar binnen 14 dagen vanaf de dag volgend op de in lid 1 bedoelde melding, zendt de consument het product terug, of overhandigt hij dit aan (een gemachtigde van) de ondernemer. Dit hoeft niet als de ondernemer heeft aangeboden het product zelf af te halen. De consument heeft de terugzendtermijn in elk geval in acht genomen als hij het product terugzendt voordat de bedenktijd is verstreken.  \n" +
      "8.3. De consument zendt het product terug met alle geleverde toebehoren, indien redelijkerwijs mogelijk in originele staat en verpakking, en conform de door de ondernemer verstrekte redelijke en duidelijke instructies. \n" +
      "8. 4. Het risico en de bewijslast voor de juiste en tijdige uitoefening van het herroepingsrecht ligt bij de consument.  \n" +
      "8.5. De consument draagt de rechtstreekse kosten van het terugzenden van het product. Als de ondernemer niet heeft gemeld dat de consument deze kosten moet dragen of als de ondernemer aangeeft de kosten zelf te dragen, hoeft de consument de kosten voor terugzending niet te dragen.  \n" +
      "8. 6. Als de consument gebruik maakt van zijn herroepingsrecht, worden alle aanvullende overeenkomsten van rechtswege ontbonden. ",
  tos17: "​Artikel 9 - Verplichtingen van de ondernemer bij herroeping ",
  tos18:
      "9.1. Als de ondernemer de melding van herroeping door de consument op elektronische wijze mogelijk maakt, stuurt hij na ontvangst van deze melding onverwijld een ontvangstbevestiging. \n" +
      "9.2. De ondernemer vergoedt alle betalingen van de consument, inclusief eventuele leveringskosten door de ondernemer in rekening gebracht voor het geretourneerde product, onverwijld doch binnen 14 dagen volgend op de dag waarop de consument hem de herroeping meldt. Tenzij de ondernemer aanbiedt het product zelf af te halen, mag hij wachten met terugbetalen tot hij het product heeft ontvangen of tot de consument aantoont dat hij het product heeft teruggezonden, naar gelang welk tijdstip eerder valt. \n" +
      "9.3. De ondernemer gebruikt voor terugbetaling hetzelfde betaalmiddel dat de consument heeft gebruikt, tenzij de consument instemt met een andere methode. De terugbetaling is kosteloos voor de consument. \n" +
      "9.4. Als de consument heeft gekozen voor een duurdere methode van levering dan de goedkoopste standaardlevering, hoeft de ondernemer de bijkomende kosten voor de duurdere methode niet terug te betalen. ",
  tos19: "Artikel 10 - Opzegging ",
  tos20:
      "10.1. De klant kan het abonnement (dat voor onbepaalde tijd is aangegaan) te allen tijde opzeggen middels een e-mail aan hoi@tealivered.nl en stopzetten via het klant account op de website. Hierbij geldt een opzegtermijn van ten hoogste één maand.  \n" +
      "10.2. De klant kan de overeenkomst dat voor bepaalde tijd is aangegaan stopzetten na de eerste levering middels een e-mail aan hoi@tealivered.nl met opgaaf van reden. Restitutie van het aankoopbedrag geschiedt als opzegging heeft plaatsgevonden binnen de bedenktijd 14 dagen vermeld in artikel 6 van de klant.  ",
  tos21: "Artikel 11 - Klachtenregeling ",
  tos22:
      "11.1. Klachten over het product, de service, of levering dienen digitaal gedeeld te worden met de ondernemer. Mail uw klacht naar hoi@tealivered.nl en zet het woord ‘klacht’ in de onderwerpregel. \n" +
      "11.2. Uw klacht wordt in behandeling genomen en u wordt binnen 14 dagen, vanaf de datum van ontvangst, geïnformeerd door Tealivered met ontvangstbevestiging en indicatie voor een uitgebreider antwoord indien nodig.  \n" +
      "11.3. Klachten over de uitvoering van de overeenkomst moeten binnen bekwame tijd nadat de consument de gebreken heeft geconstateerd, volledig en duidelijk omschreven worden ingediend bij de ondernemer. Deze zijn digitaal in te dienen via hoi@tealivered.nl  ",
  tos23: "Artikel 12 - Geschillen ",
  tos24:
      "12.1. Op overeenkomsten tussen de ondernemer en de consument waarop deze algemene voorwaarden betrekking hebben, is uitsluitend Nederlands recht van toepassing.  ",
  tos25: "Artikel 13 - garantie en aansprakelijkheid ",
  tos26:
      "13.1. Wij garanderen slechts dat de door ons geleverde producten geschikt zijn voor het doel waarvoor zij op onze verstrekte informatie bestemd zijn en dat deze producten voldoen aan de gestelde wettelijke verplichtingen en voorschriften die gelden voor dat gebruiksdoel. \n" +
      "13.2. Voor toepassing van deze garantie is vereist dat de consument een schriftelijk beroep hierop doet bij ons. ",
  tos27: "Artikel 14  - Wijziging van de algemene voorwaarden Tealivered ",
  tos28:
      "14.1. De meest recente versie van onze algemene voorwaarden vind je op de website tealivered.nl. \n" +
      "14.2. Wij behouden het recht deze algemene voorwaarden te updaten, vervangen of tekst stukken te wijzigen bij veranderingen op onze website of van onze diensten en/of producten. Het wordt aanbevolen deze pagina regelmatig te raadplegen. ",
  tos29: " ",

  privacy: "Privacyverklaring",
  privacy1:
      "Tealivered, gevestigd aan De Brink 112, 9723AM te Groningen, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring. ",
  privacy2: "Contactgegevens: ",
  privacy3: "https://www.tealivered.nl ",
  privacy4: "De Brink 112, 9723AM te Groningen ",
  privacy5: "0646923202",
  privacy6: "Persoonsgegevens die wij verwerken ",
  privacy7:
      "Tealivered verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken: ",
  privacy8: "- Voor- en achternaam ",
  privacy9: "- Adresgegevens ",
  privacy10: "- E-mailadres ",
  privacy11: "- Bankrekeningnummer ",
  privacy12: "Bijzondere en/of gevoelige persoonsgegevens die wij verwerken ",
  privacy13:
      "Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je ervan overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via hoi@tealivered.nl, op verzoek verwijderen wij dan deze informatie. ",
  privacy14:
      "Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken Tealivered verwerkt jouw persoonsgegevens voor de volgende doelen: ",
  privacy15: "- Het afhandelen van jouw betaling  ",
  privacy16: "- Verzenden van onze nieuwsbrief en/of reclamefolder ",
  privacy17:
      "- Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren ",
  privacy18:
      "- Je te informeren over wijzigingen van onze diensten en producten ",
  privacy19: "- Je de mogelijkheid te bieden een account aan te maken ",
  privacy20: "- Om goederen en diensten bij je af te leveren ",
  privacy21:
      "- Tealivered verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte.  ",
  privacy22: "Geautomatiseerde besluitvorming ",
  privacy23:
      "Tealivered neemt verantwoordelijkheid op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Tealivered) tussen zit. Tealivered gebruikt de volgende computerprogramma's of -systemen: ",
  privacy24: "- Eigen website (volledig in eigen beheer) ",
  privacy25: "- Hostnet voor hosting van de eigen website ",
  privacy26: "- inclusief een domain name security system extentie ",
  privacy27: "- Betalingsverkeer via Mollie  ",
  privacy28: "- Microsoft software voor interne organisatie  ",
  privacy29: "Bewaartermijn ",
  privacy30:
      "Tealivered bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens: ",
  privacy31: "- totdat jij jouw account verwijderd  ",
  privacy32:
      "- E-mailadres: totdat jij verzoekt om verwijdering van je gegevens ",
  privacy33:
      "- Verzend-/adresgegevens/bankgegevens: voor 7 jaar na inactief zetten van je account ",
  privacy34: "Delen van persoonsgegevens met derden ",
  privacy35:
      "Tealivered verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Tealivered blijft verantwoordelijk voor deze verwerkingen. ",
  privacy36: "Cookies, of vergelijkbare technieken, die wij gebruiken ",
  privacy37:
      "Tealivered gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van je computer, tablet of smartphone. Tealivered gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die jouw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden.  ",
  privacy38:
      "Bij jouw eerste bezoek aan onze website hebben wij je al geïnformeerd over deze cookies en hebben we je toestemming gevraagd voor het plaatsen ervan.   ",
  privacy39:
      "Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.   ",
  privacy40:
      "Zie voor een toelichting: https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/  ",
  privacy41:
      "Op deze website worden ook cookies geplaatst door derden. Dit zijn bijvoorbeeld adverteerders en/of de sociale media-bedrijven. Hieronder een overzicht: ",
  privacy42: "[Bijvoorbeeld: ",
  privacy43: "Cookie: Googly Analytics ",
  privacy44: "Naam: _utma ",
  privacy45: "Functie: Analytische cookie die websitebezoek meet ",
  privacy46: "Bewaartermijn: 2 jaar]  ",
  privacy47: "Gegevens inzien, aanpassen of verwijderen  ",
  privacy48:
      "Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Dit kun je zelf doen via de persoonlijke instellingen van jouw account. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door ons bedrijf en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. ",
  privacy49:
      "Wil je gebruik maken van je recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heb je andere vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar hoi@tealivered.nl.  ",
  privacy50:
      "Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. Tealivered zal zo snel mogelijk, maar in ieder geval binnen vier weken, op jouw verzoek reageren. ",
  privacy51:
      "Tealivered wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons ",
  privacy52: "Hoe wij persoonsgegevens beveiligen ",
  privacy53:
      "Tealivered neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met ons via hoi@tealivered.nl. Tealivered heeft de volgende maatregelen genomen om jouw persoonsgegevens te beveiligen:  ",
  privacy54: "- Hostnet  ",
  privacy55:
      "- domain name security system. Hiermee krijgt jouw website bezoek een digitale handtekening mee zodat je altijd bij onze website uitkomt. Hierdoor kunnen hackers je niet naar een neppe namaaksite van Tealivered brengen wanneer je onze website bezoekt.  ",
  privacy56: "- Beveiligingssoftware, zoals een virusscanner en firewall. ",
  privacy57: "- Multifactor authenticate voor onze interne organisatie ",
  privacy58:
      "- TLS (voorheen SSL) Wij versturen jouw gegevens via een beveiligde internetverbinding. Dit kun je zien aan de adresbalk 'https' en het hangslotje in de adresbalk. ",
  privacy59: "",
  privacy60: "",

  about1: "Over ons",
  about2: "Ontstaan",
  about3:
      "Op een avond in 2021 is het idee geboren. Vanuit een behoefte om lekkere thee in huis te hebben kwam de vraag: waarom bezorgd niemand lekkere thee aan huis? Dan vergeet ik het ook niet weer te kopen en zo geschiede. Diezelfde avond hebben wij, Bas Jetten, Cees bij de Leij en Zenash Pouwels,  de eerste ideeën gedeeld en besproken over lekkere en goede thee voor thuis. Tealivered.  ",
  about4: "Tealivered",
  about5:
      "Bij Tealivered geloven we dat thee pas echt lekker is als het losse thee is. De thee heeft dan de ruimte om al z’n smaak af te geven. Waardoor je de pure smaak proeft. Vanuit onze visie willen we ieder huishouden qualitea leveren, zodat jij altijd echte thee kan ervaren.  ",
  about6:
      "We streven ernaar om een compleet duurzaam bedrijf te worden. Thee is een natuurproduct en we vinden het belangrijk dit natuurlijk te houden. Al onze verpakkingen zijn daarom biologisch afbreekbaar.",
  about7: "Thee aanbod ",
  about8:
      "Omdat we ook geloven dat lekkere thee voor thuis een gemakkelijke keuze moet zijn, bieden we een selecte keuze aan biologische theesmaken. Voor de afwisseling is er geregeld een tijdelijke theesmaak in ons thee assortiment: De Thee. Zo experimenteer je erop los.   ",
  about9: "Tealivered voor thuis of op kantoor ",
  about10:
      "Mocht je nog vragen hebben over onze thee of wie wij zijn dan zijn we bereikbaar via hoi@tealivered.nl  ",
  about11:
      "Wil je tealivered in jouw bedrijf? Neem dan ook contact op via hoi@tealivered.nl om de mogelijkheden te bespreken. Jij kiest de smaak en wij verzorgen jouw voorraad. ",

  qualitea1: "Wat is Qualitea? ",
  qualitea2:
      "Bij Tealivered vinden we het belangrijk dat jij geniet van Tealivered. Onze thee is simpel met een twist. De ene keer met wat extra pit en de andere keer wat fruitiger, maar altijd pure losse thee of kruiden. Zo bieden we ieder huishouden qualitea, zodat jij altijd echte thee kan ervaren. ",
};
