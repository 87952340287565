import {PAYMENT_HISTORY_FAIL, PAYMENT_HISTORY_REQUEST, PAYMENT_HISTORY_SUCCESS} from "../constants/historyConstants.js";
import Axios from "axios";

export const paymentHistory = () => async (dispatch) => {
    try {
        dispatch({
            type: PAYMENT_HISTORY_REQUEST,
        });

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        const { data } = await Axios.get("api/orders/paymentHistory", {
            headers: {
                Authorization: " Bearer " + userInfo.token,
            },
        });

        dispatch({ type: PAYMENT_HISTORY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PAYMENT_HISTORY_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};